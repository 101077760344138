import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { baseURL } from '../../../../config/baseURL'
import saveAs from 'file-saver'
import './index.css'
import { PreloaderRing } from '../../../../components/Preloder/Preloader'


export default function Balancetes() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: 'UPDATE_BALANCETES' })
        getDadosBalancetes()
    }, [])

    const [periodo, setPeriodo] = useState('')
    const [titulo, setTitulo] = useState('')
    const [preload, setPreload] = useState("p-1 d-none")
    const [arquivo, setArquivo] = useState([])
    const [placeInput, setPlaceInput] = useState('Arraste aqui ou clique em Browse')
    const [styleInput, setStyleInput] = useState("custom-file-label")
    const [alertDiv, setAlertDiv] = useState('alert alert-danger form-group col-md-12 mt-5 d-none')
    const [msgAlert, setmsgAlert] = useState('')
    const [arrayBalancete, setArrayBalancete] = useState([])
    const [opacity, setOpacity] = useState('none')

    async function getDadosBalancetes() {

        const response = await fetch(`${baseURL}balancete-index-all`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        const respJSON = await response.json()
        setArrayBalancete(respJSON)
    }

    async function removerBalancete(id, nome_arquivo) {
        setOpacity('1')

        const response = await fetch(`${baseURL}balancete-delete`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {

                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`

            },
            body: JSON.stringify({
                id,
                nome_arquivo
            })
        })

        if (response.status === 404 || response.status === 400 || response.status === 500
            || response.status === 506) {
            setOpacity('0')
            setmsgAlert('Erro de servidor, entre em contato com administrador do sistema.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        const respJSON = await response.json()

        if (respJSON.erro) {
            setOpacity('0')
            setmsgAlert(respJSON.erro + ' Entre em contato com o administrador do sistema.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        if (respJSON === 0) {
            setOpacity('0')
            setmsgAlert('Ocorreu algum erro interno, entre em contato com o administrador do sistema.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        setOpacity('0')
        setmsgAlert('Balancete removido com sucesso.')
        setAlertDiv('alert my-2 alert-success form-group col-md-12')
        getDadosBalancetes()
    }

    async function baixarBalancete(nome_arquivo) {

        const response = await fetch(`${baseURL}balancete?nome_arquivo=${nome_arquivo}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        const blobFile = await response.blob()
        let fileName

        switch (blobFile.type) {
            case 'application/pdf':
                fileName = 'BalanceteCaxias.pdf'
                break;
            case 'image/jpeg':
                fileName = 'BalanceteCaxias.jpeg'
                break;
            case 'image/pjpeg':
                fileName = 'BalanceteCaxias.pjpeg'
                break;
            case 'image/png':
                fileName = 'BalanceteCaxias.png'
                break;
            default:
                fileName = 'BalanceteCaxias'
                break;
        }
        saveAs(blobFile, fileName)
    }

    async function inserirBalancete(event) {
        event.preventDefault();

        setPreload('p-1')

        if (titulo.length === 0 || periodo.length === 0) {
            setPreload('d-none')
            setmsgAlert('Existem campos em branco.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        if (arquivo.length === 0) {
            setPreload('d-none')
            setmsgAlert('Insira o arquivo do balancete.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        const data = new FormData()
        data.append('file', arquivo)
        data.append('titulo', titulo)
        data.append('periodo', periodo)

        const response = await fetch(`${baseURL}balancete`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                "authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: data,

        })

        if (response.status === 404 || response.status === 400 || response.status === 500
            || response.status === 401 || response.status === 506) {
            setOpacity('0')
            setmsgAlert('Erro de servidor, entre em contato com administrador do sistema.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        setPreload('d-none')
        setmsgAlert('Balancete inserido com sucesso.')
        setAlertDiv('alert my-2 alert-success form-group col-md-12')
        getDadosBalancetes()
    }

    return (

        <div className="container">

            <div className="row  justify-content-center">
                <div className="col-md-11 py-2 px-3  rowPrincipal">
                    <div className="col-md-12 text-center">
                        <h3>Inserir Balancetes</h3>
                    </div>

                    <form onSubmit={inserirBalancete} className="needs-validation">
                        <div className="form-group">
                            <label htmlFor="periodo"> <strong> Período do Balancete </strong></label>
                            <input type="text" className="form-control" id="periodo"
                                value={periodo} onChange={event => setPeriodo(event.target.value)}
                                onClick={() => setAlertDiv('d-none')} required />
                            <small id="periodo" className="form-text text-muted">Insira o período referente ao balancete ex: (Jan/2019 a Dez/2019)</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="titulo"> <strong> Título do Balancete </strong></label>
                            <input type="text" className="form-control" id="titulo"
                                value={titulo} onChange={event => setTitulo(event.target.value)}
                                onClick={() => setAlertDiv('d-none')} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="customFile"> <strong> Insira o arquivo </strong></label>
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="customFile"
                                    onClick={() => setAlertDiv('d-none')}
                                    onChange={(event) => {
                                        if (event.target.files[0] !== undefined) {
                                            if (event.target.files[0].type !== 'application/pdf' && event.target.files[0].type !== 'image/png'
                                                && event.target.files[0].type !== 'image/pjpeg' && event.target.files[0].type !== 'image/jpeg') {
                                                setPreload('d-none')
                                                setPlaceInput('Formato de arquivo inválido.')
                                                return setStyleInput("custom-file-label text-danger")
                                            }
                                            setArquivo(event.target.files[0])
                                            event.target.files[0] !== undefined ? setPlaceInput(event.target.files[0].name) : setPlaceInput('Arraste aqui ou clique em Browse')
                                            setStyleInput("custom-file-label text-success")
                                        }

                                    }} />
                                <label className={styleInput} htmlFor="customFile"> {placeInput} </label>
                                <small id="periodo" className="form-text text-muted">Insira aqui o arquivo PDF ou IMAGEM do balancete.</small>
                            </div>
                        </div>

                        <div className="row ml-2">
                            <button type="submit" className="btn btn-info  my-2">Inserir</button>
                            <button className="btn btnDownload m-2">Limpar</button>
                            <div className={preload}>  <PreloaderRing /> </div>
                        </div>

                        <div className={alertDiv} role="alert" >
                            <strong> {msgAlert}  </strong>
                        </div>

                        <div className="col-md-12 text-center mb-3">
                            <h3>Balancetes Financeiros Cadastrados</h3>
                        </div>

                    </form>
                    <div className="col-md-12 mt-3 px-0" style={{ overflow: 'auto' }}>
                        <table className="table table-hover text-center" style={{ overflow: 'auto', borderStyle: 'solid', borderWidth: '5px', borderColor: 'rgb(124, 3, 3)' }}>
                            <thead>
                                <tr className="text-light" style={{ backgroundColor: 'rgb(124, 3, 3)' }}>
                                    <th scope="col">Período</th>
                                    <th scope="col">Título</th>
                                    <th scope="col">Baixar</th>
                                    <th scope="col">Remover</th>
                                </tr>
                            </thead>
                            <tbody className="table-secondary table-bordered">
                                {arrayBalancete.map((balancete) => {
                                    return (
                                        <tr key={balancete.id} >
                                            <td className="p-auto" scope="row"> {balancete.periodo} </td>
                                            <td>{balancete.titulo}</td>
                                            <td> <button onClick={() => baixarBalancete(balancete.nome_arquivo)} className="btn btn-info text-light"> <i className="fas fa-download "></i> </button>  </td>
                                            <td> <button onClick={() => removerBalancete(balancete.id, balancete.nome_arquivo)} className="btn btnDownload text-light"><i className="fas fa-trash"></i></button> </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                    </div>

                    <div className='modalRemove' style={{ opacity: opacity }}>

                        <div className="row modalContent">

                            <h4 className="mr-3" > Removendo... </h4>
                            <PreloaderRing />
                        </div>

                    </div>


                </div>


            </div>





        </div>

    )


}