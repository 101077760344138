import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { PrivateRoute } from '../src/auth/PrivateRoute'

import Login from './pages/Login/index'
import PainelHome from './pages/Painel/index'
import NotFound from './pages/NotFound/NotFound'

export default function Routes() {

    return (
        <BrowserRouter>

            <Switch>

                <Route path="/" exact component={Login} />
                <PrivateRoute path="/painel" component={PainelHome} />
                <Route path="*" component={NotFound} />

            </Switch>

        </BrowserRouter>

    )

}
