import { createStore } from 'redux'

const INITIAL_STATE = {
    data: '',
    titulMenuSusp: 'Home',
    displayMenu: 'block'
}

function components(state = INITIAL_STATE, action) {

    switch (action.type) {
        case 'UPDATE_ACTIVE_ON':
            return { ...state, data: 'active' }

        case 'UPDATE_ACTIVE_OF':
            return { ...state, data: '' }

        case 'UPDATE_CADAST_SOCIO':
            return { ...state, titulMenuSusp: 'Cadastro e Atualização de Sócios' }

        case 'UPDATE_BUSC_SOCIO':
            return { ...state, titulMenuSusp: 'Buscar Sócios' }

        case 'UPDATE_BALANCETES':
            return { ...state, titulMenuSusp: 'Balancetes Financeiros' }

        case 'UPDATE_ATAS_REUNIOES':
            return { ...state, titulMenuSusp: 'Atas de Reuniões' }

        case 'UPDATE_SOLICITACOES':
            return { ...state, titulMenuSusp: 'Solicitações' }

        case 'UPDATE_DOCS_CLUBE':
            return { ...state, titulMenuSusp: 'Documentos do Clube' }
        case 'UPDATE_RELAC_ANIVERSARIANTES':
            return { ...state, titulMenuSusp: 'Relação de Aniversariantes' }

        case 'UPDATE_HOME':
            return { ...state, titulMenuSusp: 'Home' }

        case 'UPDATE_RELAC_SOCIOS':
            return { ...state, titulMenuSusp: 'Relação de Sócios' }

        case 'UPDATE_RELAT_ECONSIG':
            return { ...state, titulMenuSusp: 'Relatório eConsig' }

        case 'UPDATE_ALTERAR_SENHA':
            return { ...state, titulMenuSusp: 'Alterar Senha do Sócio' }

        case 'UPDATE_DISPLAY_N':
            return { ...state, displayMenu: 'none' }

        case 'UPDATE_DISPLAY_B':
            return { ...state, displayMenu: 'block' }

        default:
            return state
    }
}

const store = createStore(components)

export default store