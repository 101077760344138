import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import store from '../../store'

import './index.css';

import MenuLateral from '../../components/Menu_Lateral/MenuLateral'
import { MenuSuspenso } from '../../components/Menu Superior/MenuSuspenso'
import { authJWT } from '../../auth/auth'
import SubRoutes from '../../subRoutes'

export default function PainelHome({ history }) {

    //VERIFICAÇÃO DO TOKEN
    async function autentic() {
        if (!await authJWT()) {
            alert('Usuário não autenticado')
            return history.push('/')
        }
    }

    const [opacityBtn, setOpacityBtn] = useState('0')
    const [ponterEvent, setPonterEvent] = useState('none')

    window.onscroll = () => mostrarBtn()

    function mostrarBtn() {

        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 200) {
            setOpacityBtn('1')
            setPonterEvent('painted')
        } else {
            setOpacityBtn('0')
            setPonterEvent('none')
        }
    }

    useEffect(() => {

        if (Notification.permission !== 'granted') {
            Notification.requestPermission()
        }

        autentic()
    }, [])

    return (

        <Provider store={store}>

            <div id="wrapper" className="wrapper">

                <MenuLateral />

                <div id="content">

                    <MenuSuspenso />

                    <SubRoutes />

                </div>
            </div>

            <a href="#wrapper" className='stileBtn' style={{ opacity: opacityBtn, pointerEvents: ponterEvent }} >
                <span className="spanBtn">
                    <strong> <i className="fas fa-chevron-up"></i> </strong>
                </span>
            </a>

        </Provider>
    )

}