import React, { useState } from 'react'
import { PreloaderRingMin } from '../../../../components/Preloder/Preloader'
import { useDispatch } from 'react-redux'
import './Relatorios.css'
import LogoCaxias from '../../../../assets/img/pngCaxiasLogo.png'
import { baseURL } from '../../../../config/baseURL'

export default function Relatorio_eConsing() {

    const dispatch = useDispatch()
    dispatch({ type: 'UPDATE_RELAT_ECONSIG' })

    const [classContainer, setclassContainer] = useState("container-fluid  rounded-lg border border-danger mb-3 pt-3")
    const [areaLogo, setAreaLogo] = useState('row align-items-center justify-content-center d-none')
    const [displayNone, setDisplayNone] = useState({ dispay: '' })
    const [inputFile, setinputFile] = useState("custom-file-label")
    const [msgAlert, setmsgAlert] = useState('')
    const [classAlertBuscSocio, setclassAlertBuscSocio] = useState('alert alert-danger form-group col-md-12 mt-5 d-none')
    const [preloadRingMin, setPreloadRingMin] = useState('d-none')
    const [conteudoInput, setConteudoInput] = useState('Arraste aqui o arquivo ou clique em Browse:')
    const [arquivo, setArquivo] = useState([])
    const [dispayTable, setDispayTable] = useState('d-none')

    const [totalGastos, setTotalGastos] = useState(0)
    const [totalcontriAssoci, setTotalcontriAssoci] = useState(0)
    const [totalconvAlimen, setTotalconvAlimen] = useState(0)
    const [totaljoia, setTotalJoia] = useState(0)
    const [totalmensalidade, setTotalmensalidade] = useState(0)

    const [apPMArrayDadosMensali, setApPMArrayDadosMensali] = useState([])
    const [apPMArrayDadosContrAssoc, setApPMArrayDadosContrAssoc] = useState([])
    const [apPMArrayDadosConvAliment, setApPMArrayDadosConvAliment] = useState([])
    const [apPMArrayDadosJoia, setApPMArrayDadosJoia] = useState([])
    const [apPMtotalconvAlim, setapPMtotalconvAlim] = useState(0)
    const [apPMtotalContrAsso, setapPMtotalContrAsso] = useState(0)
    const [apPMtotalMens, setapPMtotalMens] = useState(0)
    const [apPMtotalJoia, setapPMtotalJoia] = useState(0)
    const [apPMtotalGeral, setapPMtotalGeral] = useState(0)

    const [atPMArrayDadosMensali, setAtPMArrayDadosMensali] = useState([])
    const [atPMArrayDadosContrAssoc, setAtPMArrayDadosContrAssoc] = useState([])
    const [atPMArrayDadosConvAliment, setAtPMArrayDadosConvAliment] = useState([])
    const [atPMArrayDadosJoia, setAtPMArrayDadosJoia] = useState([])
    const [atPMtotalJoia, setatPMtotalJoia] = useState(0)
    const [atPMtotalconvAlim, setatPMtotalconvAlim] = useState(0)
    const [atPMtotalContrAsso, setatPMtotalContrAsso] = useState(0)
    const [atPMtotalMens, setatPMtotalMens] = useState(0)
    const [atPMtotalGeral, setatPMtotalGeral] = useState(0)

    const [atBMArrayDadosMensali, setAtBMArrayDadosMensali] = useState([])
    const [atBMArrayDadosContrAssoc, setAtBMArrayDadosContrAssoc] = useState([])
    const [atBMArrayDadosConvAliment, setAtBMArrayDadosConvAliment] = useState([])
    const [atBMArrayDadosJoia, setAtBMArrayDadosJoia] = useState([])
    const [atBMtotalJoia, setatBMtotalJoia] = useState(0)
    const [atBMtotalconvAlim, setatBMtotalconvAlim] = useState(0)
    const [atBMtotalContrAsso, setatBMtotalContrAsso] = useState(0)
    const [atBMtotalMens, setatBMtotalMens] = useState(0)
    const [atBMtotalGeral, setatBMtotalGeral] = useState(0)

    const [hpmArrayDadosMensali, setHpmArrayDadosMensali] = useState([])
    const [hpmArrayDadosContrAssoc, setHpmArrayDadosContrAssoc] = useState([])
    const [hpmArrayDadosConvAliment, setHpmArrayDadosConvAliment] = useState([])
    const [hpmArrayDadosJoia, setHpmArrayDadosJoia] = useState([])
    const [hpmtotalJoia, sethpmtotalJoia] = useState(0)
    const [hpmtotalconvAlim, sethpmtotalconvAlim] = useState(0)
    const [hpmtotalContrAsso, sethpmtotalContrAsso] = useState(0)
    const [hpmtotalMens, sethpmtotalMens] = useState(0)
    const [hpmtotalGeral, sethpmtotalGeral] = useState(0)

    const [outrArrayDadosMensali, setOutrArrayDadosMensali] = useState([])
    const [outrArrayDadosContrAssoc, setOutrArrayDadosContrAssoc] = useState([])
    const [outrArrayDadosConvAliment, setOutrArrayDadosConvAliment] = useState([])
    const [outrArrayDadosJoia, setOutrArrayDadosJoia] = useState([])
    const [outrtotalJoia, setoutrtotalJoia] = useState(0)
    const [outrtotalconvAlim, setoutrtotalconvAlim] = useState(0)
    const [outrtotalContrAsso, setoutrtotalContrAsso] = useState(0)
    const [outrtotalMens, setoutrtotalMens] = useState(0)
    const [outrtotalGeral, setoutrtotalGeral] = useState(0)
    const [numSocios, setNumSocios] = useState(0)

    //TRANSFORMA NUMERO EM FORMATO DE MOEDA
    function formatReal(int) {
        var tmp = int + '';
        tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
        if (tmp.length > 6)
            tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        return tmp;
    }


    async function enviarRel() {
        setclassAlertBuscSocio('alert mt-n2 alert-danger form-group col-md-12 mt-5 d-none')
        setinputFile('custom-file-label')

        setPreloadRingMin('')

        if (arquivo.length === 0) {
            setPreloadRingMin('d-none')
            setmsgAlert('Insira o arquivo do eConsig do tipo texto')
            setinputFile('custom-file-label border border-danger text-danger')
            return setclassAlertBuscSocio('alert mt-n2 alert-danger form-group col-md-12 mt-5')
        }

        if (arquivo.type !== 'text/plain') {
            setPreloadRingMin('d-none')
            setmsgAlert('Formato de arquivo inválido, insira o arquivo do eConsig do tipo texto (.txt)')
            setinputFile('custom-file-label border border-danger text-danger')
            return setclassAlertBuscSocio('alert mt-n2 alert-danger form-group col-md-12 mt-5')
        }

        setinputFile('custom-file-label border border-success text-success')

        const data = new FormData()
        data.append('file', arquivo)

        const response = await fetch(baseURL + 'relatorio-econsig', {
            method: 'POST',
            mode: 'cors',
            headers: {
                "authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: data
        })

        const respJSON = await response.json()

        if (respJSON.erro) {
            setPreloadRingMin('d-none')
            setmsgAlert(`Erro de autenticação: (${respJSON.erro})`)
            setinputFile('custom-file-label border border-danger text-danger')
            return setclassAlertBuscSocio('alert mt-n2 alert-danger form-group col-md-12 mt-5')
        }

        setPreloadRingMin('d-none')

        setNumSocios(respJSON.totalSocios)

        setTotalGastos(formatReal(respJSON.totalGeral))
        setTotalcontriAssoci(formatReal(respJSON.totalContrAsso))
        setTotalconvAlimen(formatReal(respJSON.totalconvAlim))
        setTotalJoia(formatReal(respJSON.totalJoia))
        setTotalmensalidade(formatReal(respJSON.totalMens))

        setApPMArrayDadosMensali(respJSON.aposentadosPM[0].apPMArrayDadosMensali)
        setApPMArrayDadosContrAssoc(respJSON.aposentadosPM[0].apPMArrayDadosContrAssoc)
        setApPMArrayDadosConvAliment(respJSON.aposentadosPM[0].apPMArrayDadosConvAliment)
        setApPMArrayDadosJoia(respJSON.aposentadosPM[0].apPMArrayDadosJoia)
        setapPMtotalJoia(respJSON.aposentadosPM[0].apPMtotalJoia)
        setapPMtotalconvAlim(respJSON.aposentadosPM[0].apPMtotalconvAlim)
        setapPMtotalContrAsso(respJSON.aposentadosPM[0].apPMtotalContrAsso)
        setapPMtotalMens(respJSON.aposentadosPM[0].apPMtotalMens)
        setapPMtotalGeral(respJSON.aposentadosPM[0].apPMtotalGeral)

        setAtPMArrayDadosMensali(respJSON.ativosPM[0].atPMArrayDadosMensali)
        setAtPMArrayDadosContrAssoc(respJSON.ativosPM[0].atPMArrayDadosContrAssoc)
        setAtPMArrayDadosConvAliment(respJSON.ativosPM[0].atPMArrayDadosConvAliment)
        setAtPMArrayDadosJoia(respJSON.ativosPM[0].atPMArrayDadosJoia)
        setatPMtotalJoia(respJSON.ativosPM[0].atPMtotalJoia)
        setatPMtotalconvAlim(respJSON.ativosPM[0].atPMtotalconvAlim)
        setatPMtotalContrAsso(respJSON.ativosPM[0].atPMtotalContrAsso)
        setatPMtotalMens(respJSON.ativosPM[0].atPMtotalMens)
        setatPMtotalGeral(respJSON.ativosPM[0].atPMtotalGeral)

        setAtBMArrayDadosMensali(respJSON.ativosBM[0].atBMArrayDadosMensali)
        setAtBMArrayDadosContrAssoc(respJSON.ativosBM[0].atBMArrayDadosContrAssoc)
        setAtBMArrayDadosConvAliment(respJSON.ativosBM[0].atBMArrayDadosConvAliment)
        setAtBMArrayDadosJoia(respJSON.ativosBM[0].atBMArrayDadosJoia)
        setatBMtotalJoia(respJSON.ativosBM[0].atBMtotalJoia)
        setatBMtotalconvAlim(respJSON.ativosBM[0].atBMtotalconvAlim)
        setatBMtotalContrAsso(respJSON.ativosBM[0].atBMtotalContrAsso)
        setatBMtotalMens(respJSON.ativosBM[0].atBMtotalMens)
        setatBMtotalGeral(respJSON.ativosBM[0].atBMtotalGeral)

        setHpmArrayDadosMensali(respJSON.hpm[0].hpmArrayDadosMensali)
        setHpmArrayDadosContrAssoc(respJSON.hpm[0].hpmArrayDadosContrAssoc)
        setHpmArrayDadosConvAliment(respJSON.hpm[0].hpmArrayDadosConvAliment)
        setHpmArrayDadosJoia(respJSON.hpm[0].hpmArrayDadosJoia)
        sethpmtotalJoia(respJSON.hpm[0].hpmtotalJoia)
        sethpmtotalconvAlim(respJSON.hpm[0].hpmtotalconvAlim)
        sethpmtotalContrAsso(respJSON.hpm[0].hpmtotalContrAsso)
        sethpmtotalMens(respJSON.hpm[0].hpmtotalMens)
        sethpmtotalGeral(respJSON.hpm[0].hpmtotalGeral)

        setOutrArrayDadosMensali(respJSON.outros[0].outrArrayDadosMensali)
        setOutrArrayDadosContrAssoc(respJSON.outros[0].outrArrayDadosContrAssoc)
        setOutrArrayDadosConvAliment(respJSON.outros[0].outrArrayDadosConvAliment)
        setOutrArrayDadosJoia(respJSON.outros[0].outrArrayDadosJoia)
        setoutrtotalJoia(respJSON.outros[0].outrtotalJoia)
        setoutrtotalconvAlim(respJSON.outros[0].outrtotalconvAlim)
        setoutrtotalContrAsso(respJSON.outros[0].outrtotalContrAsso)
        setoutrtotalMens(respJSON.outros[0].outrtotalMens)
        setoutrtotalGeral(respJSON.outros[0].outrtotalGeral)

        setDispayTable('')

    }

    async function imprimir() {
        await dispatch({ type: 'UPDATE_DISPLAY_N' })
        await setDisplayNone({ display: 'none' })
        await setAreaLogo('row align-items-center justify-content-center')
        await setclassContainer("container-fluid mb-3 pt-3")

        await window.print()

        await dispatch({ type: 'UPDATE_DISPLAY_B' })
        await setDisplayNone({ display: '' })
        await setAreaLogo('row align-items-center justify-content-center d-none')
        await setclassContainer("container-fluid  rounded-lg border border-danger mb-3 pt-3")

    }

    return (

        <div className={classContainer}>

            <div style={displayNone}>
                <div className="row justify-content-start mb-3">
                    <div className="col-md-auto mr-n4"> <h5>Importar documento eConsig</h5> </div>
                    <div className="col-md-auto"><i className={preloadRingMin} ><PreloaderRingMin /></i></div>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-12">
                        <div className="custom-file">
                            <input onChange={(evt) => {
                                setArquivo(evt.target.files[0])
                                evt.target.files[0] !== undefined ? setConteudoInput(evt.target.files[0].name) : setConteudoInput('Arraste aqui o arquivo ou clique em Browse:')
                            }}
                                name="file"
                                type="file" className="custom-file-input"
                                id="customFile" />

                            <label className={inputFile} htmlFor="customFile">{conteudoInput}</label>
                        </div>
                    </div>
                </div>

                <div className={classAlertBuscSocio} role="alert" >
                    <strong> {msgAlert}  </strong>
                </div>

                <button className="btn btn-secondary mt-2 mb-4" onClick={enviarRel} >Gerar Relatorio</button>
            </div>

            {/*LOGO QUE SÓ FICA VISIVEL DURANTE IMPRESSÃO */}
            <div className={areaLogo}>
                <div className="col-md-2 ">
                    <img src={LogoCaxias} className='ml-5 img-fluid' alt="" />
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>CAXIAS ESPORTE CLUBE</h1>
                        </div>
                        <div className="col-md-12">
                            <h4>FUNDADO EM 06 DE SETEMBRO DE 1940</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div className={dispayTable}>
                {/*NAVEGADOR DE ABAS*/}
                <ul style={displayNone} className="nav nav-tabs mx-0 mt-3  " id="myTab" role="tablist">
                    <li className="nav-item bg-secondary text-light  abaNavTab mr-1">
                        <a className="nav-link active" id="calcGeral-tab" data-toggle="tab" href="#calGeral" role="tab" aria-controls="calGeral" aria-selected="true"> <strong> CÁLCULOS GERAIS </strong></a>
                    </li>
                    <li className="nav-item bg-secondary text-light  abaNavTab mr-1">
                        <a className="nav-link" id="aposentPM-tab" data-toggle="tab" href="#aposentPM" role="tab" aria-controls="aposentPM" aria-selected="false"> <strong> APOSENTADOS </strong></a>
                    </li>
                    <li className="nav-item bg-secondary text-light  abaNavTab mr-1">
                        <a className="nav-link " id="ativosPM-tab" data-toggle="tab" href="#ativosPM" role="tab" aria-controls="ativosPM" aria-selected="false"> <strong> ATIVOS/PM </strong></a>
                    </li>
                    <li className="nav-item bg-secondary text-light  abaNavTab mr-1" >
                        <a className="nav-link" id="ativosBM-tab" data-toggle="tab" href="#ativosBM" role="tab" aria-controls="ativosBM" aria-selected="false"> <strong> ATIVOS/CBM </strong></a>
                    </li>
                    <li className="nav-item bg-secondary text-light  abaNavTab mr-1">
                        <a className="nav-link" id="hpm-tab" data-toggle="tab" href="#hpm" role="tab" aria-controls="hpm" aria-selected="false"> <strong> HPM </strong></a>
                    </li>
                    <li className="nav-item bg-secondary text-light  abaNavTab">
                        <a className="nav-link" id="outros-tab" data-toggle="tab" href="#outros" role="tab" aria-controls="outros" aria-selected="false"> <strong> OUTROS </strong></a>
                    </li>
                </ul>


                <div className="tab-content mx-0 p-4 mb-3 rounded-bottom" id="myTabContent" style={{ backgroundColor: 'white' }}>
                    {/*ABA DE NAVEGAÇÃO*/}
                    <div style={{ color: '#7e0202' }} className="tab-pane fade show active" id="calGeral" role="tabpanel" aria-labelledby="calcGeral-tab">

                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-10">
                                <h5 className="mb-3" >CÁLCULO DOS DADOS GERIAS - PM</h5>
                            </div>
                            <div className="col-md-1">
                                <button onClick={imprimir} style={{ backgroundColor: '#7e0202' }} className="btn mb-3 text-light" > <i className="fas fa-print"></i> </button>
                            </div>

                        </div>

                        <table className="table rounded table-secondary table-hover table-sm" style={{ fontSize: '14px' }}>
                            <tbody>
                                {/*CALCULO DOS GASTOS DOS APOSENTADOS PM */}
                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="2" scope="col "> ================ APOSENTADOS ================</th>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th scope="col ">DESCRIÇÃO</th>
                                    <th className="text-center" scope="col ">VALOR $</th>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO JOIA</th>
                                    <td className="text-center">R$ {formatReal(apPMtotalJoia)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO ALIMENTAÇÃO</th>
                                    <td className="text-center">R$ {formatReal(apPMtotalconvAlim)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONTRIBUIÇÃO ASSOCIATIVA</th>
                                    <td className="text-center">R$ {formatReal(apPMtotalContrAsso)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">MENSALIDADE</th>
                                    <td className="text-center">R$ {formatReal(apPMtotalMens)}</td>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th id="contN" scope="row">TOTAL</th>
                                    <td className="text-center"> <strong>  R$ {formatReal(apPMtotalGeral)} </strong></td>
                                </tr>

                                {/*CALCULO DOS GASTOS DOS ATIVOS PM */}
                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="2" scope="col "> ================ ATIVOS - PM ================</th>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th scope="col ">DESCRIÇÃO</th>
                                    <th className="text-center" scope="col ">VALOR $</th>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO JOIA</th>
                                    <td className="text-center">R$ {formatReal(atPMtotalJoia)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO ALIMENTAÇÃO</th>
                                    <td className="text-center">R$ {formatReal(atPMtotalconvAlim)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONTRIBUIÇÃO ASSOCIATIVA</th>
                                    <td className="text-center">R$ {formatReal(atPMtotalContrAsso)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">MENSALIDADE</th>
                                    <td className="text-center">R$ {formatReal(atPMtotalMens)}</td>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th id="contN" scope="row">TOTAL</th>
                                    <td className="text-center"> <strong>  R$ {formatReal(atPMtotalGeral)} </strong></td>
                                </tr>

                                {/*CALCULO DOS GASTOS DOS ATIVOS BM */}
                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="2" scope="col "> ================ ATIVOS - BM ================</th>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th scope="col ">DESCRIÇÃO</th>
                                    <th className="text-center" scope="col ">VALOR $</th>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO JOIA</th>
                                    <td className="text-center">R$ {formatReal(atBMtotalJoia)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO ALIMENTAÇÃO</th>
                                    <td className="text-center">R$ {formatReal(atBMtotalconvAlim)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONTRIBUIÇÃO ASSOCIATIVA</th>
                                    <td className="text-center">R$ {formatReal(atBMtotalContrAsso)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">MENSALIDADE</th>
                                    <td className="text-center">R$ {formatReal(atBMtotalMens)}</td>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th id="contN" scope="row">TOTAL</th>
                                    <td className="text-center"> <strong>  R$ {formatReal(atBMtotalGeral)} </strong></td>
                                </tr>

                                {/*CALCULO DOS GASTOS DO HPM */}
                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="2" scope="col "> ================ HOSPITAL DA POLICIA MILITAR - HPM ================</th>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th scope="col ">DESCRIÇÃO</th>
                                    <th className="text-center" scope="col ">VALOR $</th>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO JOIA</th>
                                    <td className="text-center">R$ {formatReal(hpmtotalJoia)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO ALIMENTAÇÃO</th>
                                    <td className="text-center">R$ {formatReal(hpmtotalconvAlim)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONTRIBUIÇÃO ASSOCIATIVA</th>
                                    <td className="text-center">R$ {formatReal(hpmtotalContrAsso)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">MENSALIDADE</th>
                                    <td className="text-center">R$ {formatReal(hpmtotalMens)}</td>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th id="contN" scope="row">TOTAL</th>
                                    <td className="text-center"> <strong>  R$ {formatReal(hpmtotalGeral)} </strong></td>
                                </tr>

                                {/*CALCULO DOS GASTOS DE OUTROS */}
                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="2" scope="col "> ================ OUTROS ================</th>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th scope="col ">DESCRIÇÃO</th>
                                    <th className="text-center" scope="col ">VALOR $</th>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO JOIA</th>
                                    <td className="text-center">R$ {formatReal(outrtotalJoia)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO ALIMENTAÇÃO</th>
                                    <td className="text-center">R$ {formatReal(outrtotalconvAlim)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONTRIBUIÇÃO ASSOCIATIVA</th>
                                    <td className="text-center">R$ {formatReal(outrtotalContrAsso)}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">MENSALIDADE</th>
                                    <td className="text-center">R$ {formatReal(outrtotalMens)}</td>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th id="contN" scope="row">TOTAL</th>
                                    <td className="text-center"> <strong>  R$ {formatReal(outrtotalGeral)} </strong></td>
                                </tr>

                                {/*CALCULO TODOS OS GASTOS*/}
                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="2" scope="col "> ================ CÁLCULO DE TODOS OS DADOS ACIMA ================</th>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th scope="col ">DESCRIÇÃO</th>
                                    <th className="text-center" scope="col ">VALOR $</th>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO JOIA</th>
                                    <td className="text-center">R$ {totaljoia}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONVENIO ALIMENTAÇÃO</th>
                                    <td className="text-center">R$ {totalconvAlimen}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">CONTRIBUIÇÃO ASSOCIATIVA</th>
                                    <td className="text-center">R$ {totalcontriAssoci}</td>
                                </tr>
                                <tr>
                                    <th id="contN" scope="row">MENSALIDADE</th>
                                    <td className="text-center">R$ {totalmensalidade}</td>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th id="contN" scope="row">TOTAL</th>
                                    <td className="text-center"> <strong>  R$ {totalGastos} </strong></td>
                                </tr>
                                <tr style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th id="contN" scope="row">QUANTITAVO SÓCIOS NESTE RELATÓRIO</th>
                                    <td className="text-center"> <strong> {numSocios} </strong></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    {/*ABA DE NAVEGAÇÃO*/}
                    <div style={{ overflow: 'auto' }} className="tab-pane fade" id="aposentPM" role="tabpanel" aria-labelledby="aposentPM-tab">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-10">
                                <h5 className="mb-3" style={{ color: '#7e0202' }}>BENEFICIOS PREVIDENCIARIOS - PM</h5>
                            </div>
                            <div className="col-md-1">
                                <button onClick={imprimir} style={{ backgroundColor: '#7e0202' }} className="btn mb-3 text-light" > <i className="fas fa-print"></i> </button>
                            </div>
                        </div>
                        <table className="table rounded table-secondary table-hover text-center table-sm" style={{ fontSize: '14px' }}>
                            <thead>
                                <tr className="bg-dark text-light">
                                    <th scope="col">N°</th>
                                    <th scope="col">VINCULO</th>
                                    <th scope="col">NF</th>
                                    <th className="text-left" scope="col">NOME</th>
                                    <th scope="col">SITUAÇÃO DO MILITAR</th>
                                    <th scope="col">TIPO DE GASTO</th>
                                    <th scope="col">VALOR GASTO</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ================ CONVÊNIO JOIA ================</th>
                                </tr>

                                {
                                    apPMArrayDadosJoia.map((socio) => {

                                        //contador
                                        let cont = apPMArrayDadosJoia.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal">
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(apPMtotalJoia)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ================ CONVÊNIO ALIMENTAÇÃO ================</th>
                                </tr>

                                {
                                    apPMArrayDadosConvAliment.map((socio) => {

                                        //contador
                                        let cont = apPMArrayDadosConvAliment.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal">
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }


                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(apPMtotalconvAlim)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ============== CONTRIBUIÇÃO ASSOCIATIVA ==============</th>
                                </tr>

                                {
                                    apPMArrayDadosContrAssoc.map((socio) => {

                                        //contador
                                        let cont = apPMArrayDadosContrAssoc.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(apPMtotalContrAsso)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ==================== MENSALIDADE =====================</th>
                                </tr>

                                {
                                    apPMArrayDadosMensali.map((socio) => {

                                        //contador
                                        let cont = apPMArrayDadosMensali.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal">
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(apPMtotalMens)}  </strong> </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/*ABA DE NAVEGAÇÃO*/}
                    <div style={{ overflow: 'auto' }} className="tab-pane fade" id="ativosPM" role="tabpanel" aria-labelledby="ativosPM-tab">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-10">
                                <h5 className="mb-3" style={{ color: '#7e0202' }}>ATIVOS POLICIA MILITAR DO ESPIRITO SANTO - PM</h5>
                            </div>
                            <div className="col-md-1">
                                <button onClick={imprimir} style={{ backgroundColor: '#7e0202' }} className="btn mb-3 text-light" > <i className="fas fa-print"></i> </button>
                            </div>
                        </div>
                        <table className="table rounded table-secondary table-hover text-center table-sm" style={{ fontSize: '14px' }}>
                            <thead>
                                <tr className="bg-dark text-light">
                                    <th scope="col">N°</th>
                                    <th scope="col">VINCULO</th>
                                    <th scope="col">NF</th>
                                    <th className="text-left" scope="col">NOME</th>
                                    <th scope="col">SITUAÇÃO DO MILITAR</th>
                                    <th scope="col">TIPO DE GASTO</th>
                                    <th scope="col">VALOR GASTO</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ================ CONVÊNIO JOIA ================</th>
                                </tr>

                                {
                                    atPMArrayDadosJoia.map((socio) => {

                                        //contador
                                        let cont = atPMArrayDadosJoia.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal">
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(atPMtotalJoia)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ================ CONVÊNIO ALIMENTAÇÃO ================</th>
                                </tr>

                                {
                                    atPMArrayDadosConvAliment.map((socio) => {

                                        //contador
                                        let cont = atPMArrayDadosConvAliment.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(atPMtotalconvAlim)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> =============== CONTRIBUIÇÃO ASSOCIATIVA ==============</th>
                                </tr>

                                {
                                    atPMArrayDadosContrAssoc.map((socio) => {

                                        //contador
                                        let cont = atPMArrayDadosContrAssoc.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(atPMtotalContrAsso)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ===================== MENSALIDADE ====================</th>
                                </tr>

                                {
                                    atPMArrayDadosMensali.map((socio) => {

                                        //contador
                                        let cont = atPMArrayDadosMensali.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(atPMtotalMens)}  </strong> </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*ABA DE NAVEGAÇÃO*/}
                    <div style={{ overflow: 'auto' }} className="tab-pane fade" id="ativosBM" role="tabpanel" aria-labelledby="ativosBM-tab">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-10">
                                <h5 className="mb-3" style={{ color: '#7e0202' }}>ATIVOS CORPO DE BOMBEIRO MILITAR DO ES - BM</h5>
                            </div>
                            <div className="col-md-1">
                                <button onClick={imprimir} style={{ backgroundColor: '#7e0202' }} className="btn mb-3 text-light" > <i className="fas fa-print"></i> </button>
                            </div>
                        </div>
                        <table className="table rounded table-secondary table-hover text-center table-sm" style={{ fontSize: '14px' }}>
                            <thead>
                                <tr className="bg-dark text-light">
                                    <th scope="col">N°</th>
                                    <th scope="col">VINCULO</th>
                                    <th scope="col">NF</th>
                                    <th className="text-left" scope="col">NOME</th>
                                    <th scope="col">SITUAÇÃO DO MILITAR</th>
                                    <th scope="col">TIPO DE GASTO</th>
                                    <th scope="col">VALOR GASTO</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ================ CONVÊNIO JOIA ================</th>
                                </tr>

                                {
                                    atBMArrayDadosJoia.map((socio) => {

                                        //contador
                                        let cont = atBMArrayDadosJoia.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal">
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(atBMtotalJoia)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ================ CONVÊNIO ALIMENTAÇÃO ================</th>
                                </tr>

                                {
                                    atBMArrayDadosConvAliment.map((socio) => {

                                        //contador
                                        let cont = atBMArrayDadosConvAliment.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(atBMtotalconvAlim)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> =============== CONTRIBUIÇÃO ASSOCIATIVA ==============</th>
                                </tr>

                                {
                                    atBMArrayDadosContrAssoc.map((socio) => {

                                        //contador
                                        let cont = atBMArrayDadosContrAssoc.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(atBMtotalContrAsso)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ===================== MENSALIDADE ====================</th>
                                </tr>

                                {
                                    atBMArrayDadosMensali.map((socio) => {

                                        //contador
                                        let cont = atBMArrayDadosMensali.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(atBMtotalMens)}  </strong> </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*ABA DE NAVEGAÇÃO*/}
                    <div style={{ overflow: 'auto' }} className="tab-pane fade" id="hpm" role="tabpanel" aria-labelledby="hpm-tab">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-10">
                                <h5 className="mb-3" style={{ color: '#7e0202' }}>ATIVOS HOSPITAL DA POLICIA MILITAR DO ESPIRITO SANTO - HPM</h5>
                            </div>
                            <div className="col-md-1">
                                <button onClick={imprimir} style={{ backgroundColor: '#7e0202' }} className="btn mb-3 text-light" > <i className="fas fa-print"></i> </button>
                            </div>
                        </div>
                        <table className="table rounded table-secondary table-hover text-center table-sm" style={{ fontSize: '14px' }}>
                            <thead>
                                <tr className="bg-dark text-light">
                                    <th scope="col">N°</th>
                                    <th scope="col">VINCULO</th>
                                    <th scope="col">NF</th>
                                    <th className="text-left" scope="col">NOME</th>
                                    <th scope="col">SITUAÇÃO DO MILITAR</th>
                                    <th scope="col">TIPO DE GASTO</th>
                                    <th scope="col">VALOR GASTO</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ================ CONVÊNIO JOIA ================</th>
                                </tr>

                                {
                                    hpmArrayDadosJoia.map((socio) => {

                                        //contador
                                        let cont = hpmArrayDadosJoia.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal">
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(hpmtotalJoia)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ================ CONVÊNIO ALIMENTAÇÃO ================</th>
                                </tr>

                                {
                                    hpmArrayDadosConvAliment.map((socio) => {

                                        //contador
                                        let cont = hpmArrayDadosConvAliment.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(hpmtotalconvAlim)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> =============== CONTRIBUIÇÃO ASSOCIATIVA ==============</th>
                                </tr>

                                {
                                    hpmArrayDadosContrAssoc.map((socio) => {

                                        //contador
                                        let cont = hpmArrayDadosContrAssoc.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }

                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(hpmtotalContrAsso)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="7" id="contN" scope="row"> ===================== MENSALIDADE ====================</th>
                                </tr>

                                {
                                    hpmArrayDadosMensali.map((socio) => {

                                        //contador
                                        let cont = hpmArrayDadosMensali.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="6" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(hpmtotalMens)}  </strong> </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*ABA DE NAVEGAÇÃO*/}
                    <div style={{ overflow: 'auto' }} className="tab-pane fade" id="outros" role="tabpanel" aria-labelledby="outros-tab">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-10">
                                <h5 className="mb-3" style={{ color: '#7e0202' }}>OUTROS</h5>
                            </div>
                            <div className="col-md-1">
                                <button onClick={imprimir} style={{ backgroundColor: '#7e0202' }} className="btn mb-3 text-light" > <i className="fas fa-print"></i> </button>
                            </div>
                        </div>
                        <table className="table rounded table-secondary table-hover text-center table-sm" style={{ fontSize: '14px' }}>
                            <thead>
                                <tr className="bg-dark text-light">
                                    <th scope="col">N°</th>
                                    <th scope="col">VINCULO</th>
                                    <th scope="col">NF</th>
                                    <th className="text-left" scope="col">NOME</th>
                                    <th scope="col">SITUAÇÃO DO MILITAR</th>
                                    <th scope="col">CENTRO DE CUSTO</th>
                                    <th scope="col">TIPO DE GASTO</th>
                                    <th scope="col">VALOR GASTO</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="8" id="contN" scope="row"> ================ CONVÊNIO JOIA ================</th>
                                </tr>

                                {
                                    outrArrayDadosJoia.map((socio) => {

                                        //contador
                                        let cont = outrArrayDadosJoia.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal">
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="7" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(outrtotalJoia)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="8" id="contN" scope="row"> ================ CONVÊNIO ALIMENTAÇÃO ================</th>
                                </tr>

                                {
                                    outrArrayDadosConvAliment.map((socio) => {

                                        //contador
                                        let cont = outrArrayDadosConvAliment.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoOrgao}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }

                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="7" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(outrtotalconvAlim)}  </strong> </th>
                                </tr>
                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="8" id="contN" scope="row"> =============== CONTRIBUIÇÃO ASSOCIATIVA ==============</th>
                                </tr>

                                {
                                    outrArrayDadosContrAssoc.map((socio) => {

                                        //contador
                                        let cont = outrArrayDadosContrAssoc.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoOrgao}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="7" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(outrtotalContrAsso)}  </strong> </th>
                                </tr>

                                <tr className="text-center" style={{ backgroundColor: 'gray', color: 'black' }}>
                                    <th colSpan="8" id="contN" scope="row"> ===================== MENSALIDADE ====================</th>
                                </tr>

                                {
                                    outrArrayDadosMensali.map((socio) => {

                                        //contador
                                        let cont = outrArrayDadosMensali.findIndex((soc) => soc.nf === socio.nf) + 1

                                        return (
                                            /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                            <tr key={socio.nf} data-toggle="modal" data-target="#exampleModal" >
                                                <th id="contN" scope="row">{cont}</th>
                                                <td> {socio.vinc}</td>
                                                <td id={socio.nf}> {socio.nf}</td>
                                                <td className="text-left">{socio.nome}</td>
                                                <td>{socio.situacME}</td>
                                                <td>{socio.tipoOrgao}</td>
                                                <td>{socio.tipoGasto}</td>
                                                <td> R$ {formatReal(socio.gasto)} </td>
                                            </tr>)
                                    })
                                }
                                <tr className="text-left" style={{ backgroundColor: 'gray', color: 'black' }} >
                                    <th colSpan="7" id="contN" scope="row"> <strong> TOTAL: </strong> </th>
                                    <th className="text-center" colSpan="1" id="contN" scope="row"> <strong>R$ {formatReal(outrtotalMens)}  </strong> </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>
    )
}