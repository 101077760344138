import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { PrivateRoute } from '../src/auth/PrivateRoute'

import Cadastro_Socio from './pages/Painel/Socios/Cadastro_Socio'
import Buscar_Socios from './pages/Painel/Socios/Buscar_Socios'
import Relacao_Socios from './pages/Painel/Socios/Relatorios/RelacaoSocios'
import Relatorio_eConsig from './pages/Painel/Socios/Relatorios/RelatorioEConsing'
import Balancetes from './pages/Painel/Documentos/Balancetes/index'
import AtasReunioes from './pages/Painel/Documentos/AtasReunioes/index'
import DocumentosClube from './pages/Painel/Documentos/DocumentosClube/index'
import AlterarSenha from './pages/Painel/Socios/AlterarSenha'
import Home from './pages/Painel/Home/Home'
import Solicitacoes from './pages/Painel/Solicitacoes/index'
import Aniversariantes from './pages/Painel/Socios/Relatorios/Aniversariantes'
import NotFound from './pages/NotFound/NotFound'



export default function SubRoutes() {

    return (
        <BrowserRouter>

            <Switch>

                <PrivateRoute path="/painel/" exact component={Home} />
                <PrivateRoute path="/painel/cad-socios" component={Cadastro_Socio} />
                <PrivateRoute path="/painel/busc-socios" component={Buscar_Socios} />
                <PrivateRoute path="/painel/relacao-socios" component={Relacao_Socios} />
                <PrivateRoute path="/painel/relatorio-econsig" component={Relatorio_eConsig} />
                <PrivateRoute path="/painel/balancetes" component={Balancetes} />
                <PrivateRoute path="/painel/atas-reunioes" component={AtasReunioes} />
                <PrivateRoute path="/painel/docs-clube" component={DocumentosClube} />
                <PrivateRoute path="/painel/alterar-senha" component={AlterarSenha} />
                <PrivateRoute path="/painel/solicitacoes" component={Solicitacoes} />
                <PrivateRoute path="/painel/aniversariantes" component={Aniversariantes} />
                <Route path="*" component={NotFound} />

            </Switch>

        </BrowserRouter>

    )

}
