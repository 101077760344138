import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
//Componente Preload
import { PreloaderRingMin } from '../../../components/Preloder/Preloader'
import { baseURL } from '../../../config/baseURL'

export default function Buscar_Socios() {

    //VARIAVEL PARA MUNDAR O NOME DO MENU SUSPENSO PELO REDUCER
    const dispatch = useDispatch()
    dispatch({ type: 'UPDATE_BUSC_SOCIO' })

    /*********************
   **VARIÁVEIS DE STADO**
   *********************/

    //VARIAVEIS DE CONTROLE DE COMPONENTES
    const [classAlertBuscSocio, setclassAlertBuscSocio] = useState('alert alert-danger form-group col-md-12 mt-5 d-none')
    const [msgAlert, setmsgAlert] = useState('')
    //VARIÁVEL PARA ARMAZENAR DADOS DIGITADOS NO INPUT DE BUSCA DE SOCIOS
    const [dataBuscaSocio, setDataBuscaSocio] = useState('')
    //VARIÁVEL CONTROLE DE DISPLAY DO PRELOAD
    const [preloadRingMin, setPreloadRingMin] = useState('d-none')
    //VARIÁVEL DO INPUT BUSCAR
    const [placeInputBusc, setPlaceInputBusc] = useState('Número Funcional:')

    //VARIAVEIS DOS DADOS CADASTRAIS
    const [cep, setCep] = useState('')
    const [rua, setRua] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade, setCidade] = useState('')
    const [estado, setEstado] = useState('')
    const [numero, setNumero] = useState('')
    const [num_func, setNum_func] = useState('')
    const [nome, setNome] = useState('')
    const [sexo, setSexo] = useState('')
    const [rg, setRg] = useState('')
    const [vinc_pm, setVinc_pm] = useState('')
    const [cpf, setCpf] = useState('')
    const [dt_nasc, setDt_nasc] = useState('')
    const [estado_civil, setEstado_civil] = useState('')
    const [situac_efetivo, setSituac_efetivo] = useState('')
    const [email, setEmail] = useState('')
    const [tel1, setTel1] = useState('')
    const [tel2, setTel2] = useState('')
    const [graduacao, setGraduacao] = useState('')
    const [situac_clube, setSituac_clube] = useState('')
    const [complemento, setComplemento] = useState('')
    const [arrayDados, setArrayDados] = useState([])
    const [arrayDepen, setArrayDepen] = useState([])

    //DADOS DA VIUVA
    const [nomeViuva, setNomeViuva] = useState('')
    const [cpfViuva, setCpfViuva] = useState('')

    function buscarSocio() {
        setPreloadRingMin('d-block')
        switch (placeInputBusc) {
            case 'Número Funcional:':

                buscarSocioBD('num_func', dataBuscaSocio)
                break;
            case 'Nome:':
                buscarSocioBD('nome', dataBuscaSocio)
                break;
            case 'CPF:':
                buscarSocioBD('cpf', dataBuscaSocio)
                break;

            case 'Nome Viúva:':
                return buscarSocioBD('nome_viuva', dataBuscaSocio)

            default:
                break;
        }
    }

    async function buscarSocioBD(modoBusca, data) {

        try {
            const response = await fetch(`${baseURL}socio?${modoBusca}=${data}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    "authorization": `Bearer ${localStorage.getItem('token')}`
                }
            })
            const respJSON = await response.json()

            if (respJSON.erro) {

                setPreloadRingMin('d-none')
                setmsgAlert(respJSON.erro)
                setclassAlertBuscSocio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
                return await respJSON.erro
            }

            setArrayDados(respJSON)

            setPreloadRingMin('d-none')

            setclassAlertBuscSocio('alert alert-success form-group col-md-12 d-block cadSucesso mt-3')
            setmsgAlert('Selecione um dos sócios abaixos para visualizar seus dados.')

            return await respJSON

        } catch (error) {

            setPreloadRingMin('d-none')
            setmsgAlert('Sócio ainda não cadastrado.')
            setclassAlertBuscSocio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
        }
    }

    function openModal(nf) {

        let index = 0

        for (let i = 0; i < arrayDados.length; i++) {
            if ((arrayDados[i].num_func).toString() === nf.toString()) {
                index = i
            }
        }

        //formatar da data
        let dataNasc = (arrayDados[index].dt_nasc).toString()
        let dia, mes, ano
        dia = dataNasc.substring(8, 10)
        mes = dataNasc.substring(5, 7)
        ano = dataNasc.substring(0, 4)
        dataNasc = `${dia}/${mes}/${ano}`

        setNum_func(arrayDados[index].num_func)
        setNome(arrayDados[index].nome)
        setSexo(arrayDados[index].sexo)
        setRg(arrayDados[index].rg)
        setVinc_pm(arrayDados[index].vinc_pm)
        setCpf(arrayDados[index].cpf)
        setDt_nasc(dataNasc)
        setEstado_civil(arrayDados[index].estado_civil)
        setSituac_efetivo(arrayDados[index].situac_efetivo)
        setEmail(arrayDados[index].email)
        setTel1(arrayDados[index].tel1)
        setTel2(arrayDados[index].tel2)
        setNomeViuva(arrayDados[index].nome_viuva)
        setCpfViuva(arrayDados[index].cpf_viuva)
        setGraduacao(arrayDados[index].graduacao)
        setSituac_clube(arrayDados[index].situac_clube)
        setRua(arrayDados[index].socio_enderecos[0].logradouro)
        setNumero(arrayDados[index].socio_enderecos[0].numero)
        setBairro(arrayDados[index].socio_enderecos[0].bairro)
        setCidade(arrayDados[index].socio_enderecos[0].cidade)
        setCep(arrayDados[index].socio_enderecos[0].cep)
        setComplemento(arrayDados[index].socio_enderecos[0].complemento)
        setEstado(arrayDados[index].socio_enderecos[0].uf)

        setArrayDepen(arrayDados[index].socio_dependentes)


    }

    return (

        <>

            <div className="container-fluid  rounded-lg border border-danger mb-3 pt-3 ">
                <div className="row justify-content-start ">
                    <div className="col-md-auto mr-n4"> <h5>Buscar Sócio</h5> </div>
                    <div className="col-md-auto"><i className={preloadRingMin} ><PreloaderRingMin /></i></div>
                </div>
                <div className="input-group my-3">
                    <div className="input-group-prepend">
                        <button onClick={buscarSocio} type="button" className="btn btn-outline-secondary">Buscar</button>
                        <button type="button" className="btn btn-outline-secondary  " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-sort-down"></i>
                        </button>
                        <div className="dropdown-menu" onClick={() => setDataBuscaSocio('')}>
                            <button onClick={() => setPlaceInputBusc('Número Funcional:')} className="dropdown-item" >NF</button>
                            <button onClick={() => setPlaceInputBusc('Nome:')} className="dropdown-item" >Nome</button>
                            <button onClick={() => setPlaceInputBusc('CPF:')} className="dropdown-item" >CPF</button>
                            <button onClick={() => setPlaceInputBusc('Nome Viúva:')} className="dropdown-item" >Nome Viúva</button>
                        </div>
                    </div>
                    <input id="buscarSocios" type="text" value={dataBuscaSocio}
                        onChange={event => setDataBuscaSocio(event.target.value)}
                        className="form-control"
                        aria-label="Text input with segmented dropdown button"
                        placeholder={placeInputBusc}
                        onClick={() => {
                            setclassAlertBuscSocio('alert alert-danger d-none')
                        }}
                    />
                </div>
                <div className={classAlertBuscSocio} role="alert" >
                    <strong> {msgAlert}</strong>
                </div>
            </div>

            <div style={{ overflow: 'auto' }} id="bodyBusc" className="container-fluid  rounded-lg border border-danger mb-3 pt-3 ">
                <table className="table table-striped table-hover text-center">
                    <thead>
                        <tr>
                            <th scope="col">N°</th>
                            <th scope="col">NF</th>
                            <th scope="col">NOME</th>
                            <th scope="col">POSTO / GRADUAÇÃO</th>
                            <th scope="col">SITUAÇÃO NO CLUBE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            arrayDados.map((socio) => {

                                //contador
                                let cont = arrayDados.findIndex((soc) => soc.num_func === socio.num_func) + 1

                                return (
                                    /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                    <tr data-toggle="modal" data-target="#exampleModal" key={socio.id} style={{ cursor: 'pointer' }} onClick={() => openModal(document.getElementById((socio.num_func).toString()).innerText)} >
                                        <th id="contN" scope="row">{cont}</th>
                                        <td id={socio.num_func}> {socio.num_func}</td>
                                        <td>{socio.nome}</td>
                                        <td> {socio.graduacao} </td>
                                        <td> {socio.situac_clube}</td>
                                    </tr>)
                            })
                        }
                    </tbody>
                </table>
            </div>


            {/**
                 * MODAL
                * */}

            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> <strong> DADOS DO SÓCIO SELECIONADO </strong></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4> Dados Pessoais:</h4>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <strong>NOME:</strong> {nome} <br />
                                        <strong>NF:  </strong> {num_func} <br />
                                        <strong>RG:  </strong> {rg} <br />
                                        <strong>SEXO:  </strong> {sexo} <br />
                                        <strong>E-MAIL:  </strong> {email} <br />
                                        <strong>TELEFONE:  </strong> {tel1} <br />
                                        <strong>TELEFONE:  </strong> {tel2} <br />

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <strong>CPF:</strong> {cpf} <br />
                                        <strong>VÍNCULO:</strong> {vinc_pm} <br />
                                        <strong>NASCIMENTO:  </strong> {dt_nasc} <br />
                                        <strong>ESTADO CIVIL:  </strong> {estado_civil} <br />
                                        <strong>POSTO / GRADUAÇÃO:  </strong> {graduacao} <br />
                                        <strong>SITUAÇÃO NO CLUBE:  </strong> {situac_clube} <br />
                                        <strong>SITUAÇÃO MILITAR:  </strong> {situac_efetivo} <br />
                                    </div>


                                    <div className={situac_clube !== 'FALECIDO' ? "col-md-12 border-top border-dark d-none" : "col-md-12 border-top border-dark"}>
                                        <div className="pt-3 ">
                                            <h4> Viúva:</h4>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-6 mb-4">
                                                <strong>NOME:</strong> {nomeViuva} <br />
                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <strong>CPF:</strong> {cpfViuva} <br />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="pt-3 col-md-12 border-top border-dark">
                                        <h4> Endereço:</h4>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <strong>LOGRADOURO:</strong> {rua} <br />
                                        <strong>BAIRRO:  </strong> {bairro} <br />
                                        <strong>UF:  </strong> {estado} <br />
                                        <strong>COMPLEMENTO:  </strong> {complemento} <br />

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <strong>N°:</strong> {numero} <br />
                                        <strong>MUNICÍPIO:</strong> {cidade} <br />
                                        <strong>CEP:  </strong> {cep} <br />
                                    </div>

                                    <div className="pt-3 col-md-12 mb-3 border-top border-dark">
                                        <h4>Depedentes:</h4>
                                    </div>
                                    <div className="col-md-12">

                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Nascimento</th>
                                                    <th scope="col">Parentesco</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {arrayDepen.map(depen => {

                                                    //formatar da data
                                                    let dataNasc = (depen.dt_nascimento).toString()
                                                    let dia, mes, ano
                                                    dia = dataNasc.substring(8, 10)
                                                    mes = dataNasc.substring(5, 7)
                                                    ano = dataNasc.substring(0, 4)
                                                    dataNasc = `${dia}/${mes}/${ano}`

                                                    return (
                                                        <tr key={depen.id} >
                                                            <td>{depen.nome}</td>
                                                            <td>{dataNasc}</td>
                                                            <td>{depen.parentesco}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Retornar</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}