import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar'
import './Home.css'
import Relogio from '../../../components/Relogio/Relogio'
import imgBolo from '../../../assets/img/icons/birthday-cake.png'
import imgBalao from '../../../assets/img/icons/balloon.png'
import { baseURL } from '../../../config/baseURL'

export default function Home() {

    const [contSocio, setContSocio] = useState(0)
    const [anirverMes, setAnirverMes] = useState([{ id: '01', nome: 'Não temos aniversariantes neste mês.' }])
    const [anirverDia, setAnirverDia] = useState([{ id: '01', nome: 'Não temos aniversariantes no dia de hoje.' }])
    //VARIAVEIS DE DADOS PESSOAIS
    const [nome, setNome] = useState('')
    const [sexo, setSexo] = useState('')
    const [email, setEmail] = useState('')
    const [tel1, setTel1] = useState('')
    const [tel2, setTel2] = useState('')
    const [graduacao, setGraduacao] = useState('')
    const [idadeAniver, setIdadeAniver] = useState('')
    const [mes, setMes] = useState('')
    const [diaAniver, setDiaAniver] = useState('')
    
    async function contSocios() {
        const response = await fetch(baseURL + 'situac-clube', {
            method: 'GET',
            mode: 'cors',
            headers: { "authorization": `Bearer ${localStorage.getItem('token')}` }
        })
        const respJSON = await response.json()

        if (respJSON.erro) {
            return alert(`Erro de autenticação: ${respJSON.erro}`)
        }

        setContSocio(respJSON[0].quantidade)
    }

    async function aniversariantesMes() {
        let sociosAniverDia = []
        //SIMULAR ALGUMA DATA >>>> Date(2019, 10, 20)
        const data = new Date()
        let mes = data.getMonth() + 1
        let dia = data.getDate()
        dia = ('00' + dia).slice(-2)
        mes = ("00" + mes).slice(-2)
        const response = await fetch(`${baseURL}aniver/?mes=${mes}`, {
            method: 'GET',
            mode: 'cors',
            headers: { "authorization": `Bearer ${localStorage.getItem('token')}` }
        })
        const respJSON = await response.json()

        if (respJSON.erro) {
            return setAnirverMes([{ id: '1', nome: 'Não temos aniversariantes neste mês.' }])
        }

        //VERIFICAR ANIVERSARIANTES NO DIA
        respJSON.map((socio) => {
            let socioDia = (socio.dt_nasc).toString()

            if (socioDia.substring(8, 10) === dia.toString()) {
                sociosAniverDia.push({
                    nome: socio.nome,
                    dt_nasc: socio.dt_nasc,
                    graduacao: socio.graduacao,
                    id: socio.id,
                    email: socio.email,
                    rg: socio.rg,
                    num_func: socio.num_func,
                    sexo: socio.sexo,
                    tel1: socio.tel1,
                    tel2: socio.tel2,
                    situac_efetivo: socio.situac_efetivo,
                })
            }
            return sociosAniverDia
        })
        sociosAniverDia.length !== 0 ? setAnirverDia(sociosAniverDia) : setAnirverDia([{ id: '01', nome: 'Não temos aniversariantes no dia de hoje' }])

        setAnirverMes(respJSON)
    }

    function aniversariante(nomeCompare) {
        const data = new Date()
        let index = 0
        let anoAtual = (data.getFullYear())
        let idad = 0
        let mes
        let meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

        for (let i = 0; i < anirverMes.length; i++) {

            if ((anirverMes[i].nome) === nomeCompare) {
                index = i
            }

            let anoNasc = Number((anirverMes[index].dt_nasc).substring(0, 4))
            let mesNasc = Number((anirverMes[index].dt_nasc).substring(5, 7))
            let dia = Number((anirverMes[index].dt_nasc).substring(8, 10))
            idad = anoAtual - anoNasc
            mes = meses[mesNasc - 1]

            setNome(anirverMes[index].nome)
            setSexo(anirverMes[index].sexo)
            setEmail(anirverMes[index].email)
            setTel1(anirverMes[index].tel1)
            setTel2(anirverMes[index].tel2)
            setGraduacao(anirverMes[index].graduacao)
            setIdadeAniver(idad)
            setMes(mes)
            setDiaAniver(dia)
        }
    }

    useEffect(() => {
        aniversariantesMes()
        contSocios()
    }, [])

    return (
        <>
            <h5 className="mt-n4"> Seja bem vind{localStorage.getItem('sexo') === 'M' ? 'o' : 'a'}  <strong> {localStorage.getItem('nome')}</strong>! </h5>

            <div className="container">

                <div className="row mt-4 justify-content-between">

                    <div className="col-md-6  p-0 content-aniver mb-3 shadow-sm footerBorder">

                        <div className="col-md-12 p-0">

                            <div className="text-center headerAniver1">
                                <span className="headerSpanAniver" >Aniversariantes do Dia</span>
                            </div>
                            {
                                anirverDia.map((socio) => {
                                    return (
                                        <div onClick={() => {
                                            document.getElementById(socio.num_func) !== null ? aniversariante(document.getElementById(socio.num_func.toString()).innerText) : alert('Não temos aniversariantes no dia de hoje.')
                                        }}
                                            key={socio.id} className="bodyAniver" data-toggle="modal" data-target="#modalAnivDia">
                                            <strong id={socio.num_func} > {socio.nome} </strong>
                                        </div>
                                    )
                                })}

                            <div className="text-center headerAniver2 p-auto">
                                <span className="headerSpanAniver">Aniversariantes do Mês</span>
                            </div>
                            <div className="areaAniverMes">
                                {anirverMes.map((socio) => {
                                    //formatar da data
                                    let dataNasc = socio.dt_nasc !== undefined ? socio.dt_nasc.toString() : ''
                                    let dia, mes, ano
                                    dia = dataNasc.substring(8, 10)
                                    mes = dataNasc.substring(5, 7)
                                    ano = dataNasc.substring(0, 4)
                                    dataNasc = `${dia}/${mes}/${ano}`

                                    return (
                                        <div onClick={() => aniversariante(document.getElementById(socio.num_func.toString()).innerText)} key={socio.id} className="bodyAniver" data-toggle="modal" data-target="#modalAnivMes" >
                                            <div className="row p-0 m-0 justify-content-between">

                                                <strong id={socio.num_func}> {socio.nome} </strong> <strong className="m-0 p-0"> {dataNasc} </strong>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>

                    <div className=" col-md-5 content-calend p-4 shadow-sm ">
                        <div className="col-md-12 align-self-center text-center ">
                            <Calendar className="calendar" locale="pt-BR" value={new Date()} />
                        </div>

                        <div className="col-md-12   body-horas text-center">
                            <strong>  Horas: </strong>  <Relogio />
                        </div>
                        <div className="col-md-12  dody-numSocios text-center">
                            <strong>    Sócios Ativos: </strong > <strong className="strong"> {contSocio} </strong>
                        </div>
                    </div>

                </div>
            </div>

            {/**********************
            **MODAIS DE ANIVERSÁRIO**
            ***********************/}

            {/* MODAL PARA ANIVERSARIANTES DO MÊS*/}
            <div className="modal fade" id="modalAnivMes" role="dialog" aria-labelledby="modalAnivMesLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content body-modal-mes">
                        <div className="modal-header ">
                            <h5 className="modal-title mx-auto text-light" id="modalAnivDiaLabel"> <strong> <i className="mr-3"> <img src={imgBalao} alt="Imagem Emoticon" />
                            </i> Sócio Aniversariante do Mês! <i className="ml-3"> <img src={imgBolo} alt="Imagem Emoticon" /> </i></strong></h5>
                        </div>
                        <div className="modal-body bg-light text-center">
                            Este mês {sexo === 'M' ? 'o' : 'a'} <strong>{graduacao}</strong>, <strong> {nome} </strong> faz
                             <strong> {idadeAniver} anos</strong> no dia, <strong>{diaAniver} de {mes}.</strong> Fique atento
                         aos aniversariantes do dia, para não deixar esta data especial passar em branco. <br />
                            <div className="mt-3">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Fechar</button>
                            </div>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div>

            {/* MODAL PARA ANIVERSARIANTES DO MÊS*/}

            <div className="modal fade" id="modalAnivDia" role="dialog" aria-labelledby="modalAnivDiaLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content body-modal-mes">
                        <div className="modal-header text-light text-center">
                            <h5 className="modal-title mx-auto" id="modalAnivDiaLabel"> <strong> <i className="mr-3"> <img src={imgBalao} alt="Imagem Emoticon" />
                            </i> Sócio Aniversariante do Dia! <i className="ml-3"> <img src={imgBolo} alt="Imagem Emoticon" /> </i></strong></h5>
                        </div>
                        <div id='msgAniverDia' className="modal-body bg-light text-center">
                            Hoje {sexo === 'M' ? 'o' : 'a'} <strong>{graduacao}, </strong> <strong>{nome}</strong> faz <strong>{idadeAniver} anos</strong> de vida,
                             que tal parabeniza-l{sexo === 'M' ? 'o' : 'a'} utilizando um dos contatos abaixo. <br />

                        </div>
                        <div className="modal-body bg-light text-left">
                            <strong>E-mail:</strong> {email} <br />
                            <strong>Telefone:</strong> {tel1} <br />
                            <strong>Telefone:</strong> {tel2} <br />
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
} 
