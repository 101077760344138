import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import './Socio.css'

//Componente Preload
import { PreloaderRing, PreloaderRingMin } from '../../../components/Preloder/Preloader'
import { baseURL } from '../../../config/baseURL';

//FUNÇÃO ASSINCRONA PARA VERIFICAR SE O TOKEN É VÁLIDO
export default function Cadastro_Socio() {

    //VARIAVEL PARA MUNDAR O NOME DO MENU SUSPENSO PELO REDUCER
    const dispatch = useDispatch()
    dispatch({ type: 'UPDATE_CADAST_SOCIO' })

    /*********************
    **VARIÁVEIS DE STADO**
    *********************/

    //VARIAVEIS DE CONTROLE DE COMPONENTES
    const [classAlert, setclassAlert] = useState('alert alert-danger form-group col-md-12 d-none')
    const [classAlertCampoVazio, setclassAlertCampoVazio] = useState('alert alert-danger form-group col-md-12 mt-5 d-none')
    const [classAlertBuscSocio, setclassAlertBuscSocio] = useState('alert alert-danger form-group col-md-12 mt-5 d-none')
    const [classAlertBuscSocio2, setclassAlertBuscSocio2] = useState('alert alert-danger form-group col-md-12 mt-5 d-none')
    const [classAlertDepen, setclassAlertDepen] = useState('alert alert-danger form-group col-md-12 mt-5 d-none')
    const [msgAlert, setmsgAlert] = useState('')
    //VARIÁVEL PARA ARMAZENAR DADOS DIGITADOS NO INPUT DE BUSCA DE SOCIOS
    const [dataBuscaSocio, setDataBuscaSocio] = useState('')
    //VARIÁVEL CONTROLE DE DISPLAY DO PRELOAD
    const [preloadRingMin, setPreloadRingMin] = useState('d-none')
    const [preloadRingMinCep, setPreloadRingMinCep] = useState('col-md-auto d-none')
    const [preloadRingCadas, setPreloadRingCadas] = useState('d-none mr-4')
    //VARIÁVEIS DE CONTROLE DE STYLE DOS BOTÕES CADASTRAR E ATUALIZAR
    const [btnCadastrar, setBtnCadastrar] = useState('btn btn-primary mr-3 d-block')
    const [btnAtualizar, setBtnAtualizar] = useState('btn btn-success mr-3 d-none')
    const [btnCadastrarModal, setBtnCadastrarModal] = useState('btn btn-primary d-block')
    const [btnAtualizarModal, setBtnAtualizarModal] = useState('btn btn-success d-none')

    //VARIAVEIS DOS DADOS CADASTRAIS
    const [idSocio, setIdSocio] = useState('')
    const [cep, setCep] = useState('')
    const [rua, setRua] = useState('')
    const [bairro, setBairro] = useState('')
    const [cidade, setCidade] = useState('')
    const [estado, setEstado] = useState('')
    const [numero, setNumero] = useState('')
    const [num_func, setNum_func] = useState('')
    const [nome, setNome] = useState('')
    const [sexo, setSexo] = useState('')
    const [rg, setRg] = useState('')
    const [vinc_pm, setVinc_pm] = useState('')
    const [cpf, setCpf] = useState('')
    const [dt_nasc, setDt_nasc] = useState('')
    const [estado_civil, setEstado_civil] = useState('')
    const [situac_efetivo, setSituac_efetivo] = useState('')
    const [email, setEmail] = useState('')
    const [tel1, setTel1] = useState('')
    const [tel2, setTel2] = useState('')
    const [graduacao, setGraduacao] = useState('')
    const [situac_clube, setSituac_clube] = useState('')
    const [complemento, setComplemento] = useState('')
    const [nomeDepen, setNomeDepen] = useState('')
    const [nascimentoDepen, setNascimentoDepen] = useState('')
    const [parentescoDepen, setParentescoDepen] = useState('')
    const [arrayDepen, setArrayDepen] = useState([])
    const [copyDadosSocioBusc, setCopyDadosSocioBusc] = useState('')

    //DADOS DA VIUVA
    const [nomeViuva, setNomeViuva] = useState('')
    const [cpfViuva, setCpfViuva] = useState('')

    //VARIÁVEL DO INPUT BUSCAR
    const [placeInputBusc, setPlaceInputBusc] = useState('Número Funcional:')

    /**********
    **FUNÇÕES**
    **********/

    async function buscarCep() {
        setPreloadRingMinCep('col-md-auto d-block')

        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
            const respJSON = await response.json()

            if (await respJSON.erro) {
                setPreloadRingMinCep('col-md-auto d-none')
                setmsgAlert(`CEP não encontrado`)
                return setclassAlert('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }

            setPreloadRingMinCep('col-md-auto d-none')
            setBairro(respJSON.bairro)
            setCidade(respJSON.localidade)
            setEstado(respJSON.uf)
            setRua(respJSON.logradouro)
        } catch (error) {

            setPreloadRingMinCep('col-md-auto d-none')
            setmsgAlert(`CEP não encontrado ou inválido`)
            return setclassAlert('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
        }

    }

    function addDepend() {
        setclassAlertDepen('alert alert-danger form-group col-md-12 mt-2 d-none')

        if (nomeDepen === '' || parentescoDepen === '' || nascimentoDepen === '') {
            setmsgAlert('Peencha todos os campos dos depedentes.')
            return setclassAlertDepen('alert alert-danger form-group col-md-12 mt-2 ')
        }

        setArrayDepen(
            [...arrayDepen,
            {
                id: arrayDepen.length + 1,
                nome: nomeDepen,
                dt_nascimento: nascimentoDepen,
                parentesco: parentescoDepen
            }
            ]
        )
    }

    function removeDepen(idDepen) {
        let arrayDependente

        arrayDependente = arrayDepen.filter((f) => {
            return f.id !== idDepen
        })

        setArrayDepen(arrayDependente)

        setNomeDepen('')
        setParentescoDepen('')
        setNascimentoDepen('')

    }

    async function salvarDadosBD() {
        setPreloadRingCadas('d-block mr-4')

        //VERIFICAÇÃO, SE EXISTEM CAMPOS OBRIGATORIOS EM BRANCO
        if (num_func !== '' && nome !== '' && sexo !== '' && rg !== '' & situac_clube !== ''
            && vinc_pm !== '' & cpf !== '' & dt_nasc !== '' & estado_civil !== ''
            && situac_efetivo !== '' && tel1 !== '' && graduacao !== '' && numero !== '') {

            /*VALIDAÇÃO SIMPLES DE NF, VERIFICA SE O NF É MUITO GRANDE */
            if (num_func.length > 7) {
                setPreloadRingCadas('d-none mr-4')
                setmsgAlert(`Erro: Número de caracteres no NÚMERO FUNCIONAL é superior ao permitido.`)
                return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }

            /*VALIDAÇÃO SIMPLES DE RG, VERIFICA SE O RG É MUITO GRANDE */
            if (rg.length > 7) {
                setPreloadRingCadas('d-none mr-4')
                setmsgAlert(`Erro: Número de caracteres no RG é superior ao permitido.`)
                return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }

            /*VALIDAÇÃO SIMPLES DE CPF, FUTURAMENTE INSERIR UMA VALIDAÇÃO MAIS FORTE */
            if (cpf.length !== 14) {
                setPreloadRingCadas('d-none mr-4')
                setmsgAlert(`Erro: CPF inválido.`)
                return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }

            const idUserAdmin = localStorage.getItem('id')
            const response = await fetch(`${baseURL}socio/${idUserAdmin}/cadastro`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    num_func: Number(num_func),
                    rg: Number(rg),
                    nome: nome?.toUpperCase(),
                    cpf: cpf?.toUpperCase(),
                    email: email?.toUpperCase(),
                    sexo: sexo?.toUpperCase(),
                    vinc_pm,
                    dt_nasc: dt_nasc?.toUpperCase(),
                    graduacao: graduacao?.toUpperCase(),
                    estado_civil: estado_civil?.toUpperCase(),
                    situac_efetivo: situac_efetivo?.toUpperCase(),
                    situac_clube: situac_clube?.toUpperCase(),
                    tel1: tel1,
                    tel2: tel2,
                    nome_viuva: nomeViuva,
                    cpf_viuva: cpfViuva
                })
            })
            const respJSON = await response.json()
            const idSocio = respJSON.id

            //VERIFICAÇÃO SE JÁ EXISTE SOCIO COM O NF CADASTRADO 
            if (await respJSON.erro) {
                setPreloadRingCadas('d-none mr-4')
                setmsgAlert(respJSON.erro)
                return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }

            //CADASTRO DOS DEPENDENTES DO SOCIO PELO ID
            addDepenBD(idSocio)

            //CADASTRO DO ENDEREÇO
            const responseEnder = await fetch(`${baseURL}socio/${idSocio}/endereco`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    cep: cep,
                    logradouro: rua?.toUpperCase(),
                    uf: estado?.toUpperCase(),
                    cidade: cidade?.toUpperCase(),
                    bairro: bairro?.toUpperCase(),
                    numero: Number(numero),
                    complemento: complemento?.toUpperCase(),
                    situacao: 'ATIVO'
                })
            })

            const respEnderJSON = await responseEnder.json()

            if (respEnderJSON.erro) {
                setPreloadRingCadas('d-none mr-4')
                setmsgAlert(respEnderJSON.erro)
                return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }

            setPreloadRingCadas('d-none mr-4')
            setmsgAlert('Sócio cadastrado com sucesso!')
            return setclassAlertCampoVazio('alert alert-success form-group col-md-12 mt-3 d-block cadSucesso')

        } else {
            setPreloadRingCadas('d-none mr-4')
            setmsgAlert(`Erro: Existem campos obrigatórios em branco.`)
            return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
        }
    }

    function buscarSocio() {
        setPreloadRingMin('d-block')

        switch (placeInputBusc) {
            case 'Número Funcional:':
                return buscarSocioBD('num_func', dataBuscaSocio)

            case 'Nome:':
                return buscarSocioBD('nome', dataBuscaSocio)

            case 'CPF:':
                return buscarSocioBD('cpf', dataBuscaSocio)

            case 'Nome Viúva:':
                return buscarSocioBD('nome_viuva', dataBuscaSocio)

            default:
                break;
        }



    }

    async function buscarSocioBD(modoBusca, data) {

        try {
            const response = await fetch(`${baseURL}socio?${modoBusca}=${data}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    "authorization": `Bearer ${localStorage.getItem('token')}`
                }
            })
            const respJSON = await response.json()
            //PASSAR OS DADOS PARA A VARIAVEL COPIA PARA COMPARAR OS DADOS NA HR DA ATUALIZAÇÃO

            if (respJSON.erro) {
                setclassAlertBuscSocio2('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
                setPreloadRingMin('d-none')
                setmsgAlert('Sócio ainda não cadastrado ou não encontrado, verifique se o modo de busca está correto.')
                setBtnCadastrar('btn btn-primary mr-3 d-block')
                setBtnAtualizar('btn btn-success mr-3 d-none')
                return limparDados()

            }

            setCopyDadosSocioBusc(respJSON[0])

            setIdSocio(respJSON[0]?.id)
            setNum_func(respJSON[0]?.num_func)
            setNome(respJSON[0]?.nome)
            setSexo(respJSON[0]?.sexo)
            setRg(respJSON[0]?.rg)
            setVinc_pm(respJSON[0]?.vinc_pm)
            setCpf(respJSON[0]?.cpf)
            setDt_nasc(respJSON[0]?.dt_nasc)
            setEstado_civil(respJSON[0]?.estado_civil)
            setSituac_efetivo(respJSON[0]?.situac_efetivo)
            setEmail(respJSON[0]?.email)
            setTel1(respJSON[0]?.tel1)
            setTel2(respJSON[0]?.tel2)
            setNomeViuva(respJSON[0]?.nome_viuva)
            setCpfViuva(respJSON[0]?.cpf_viuva)
            setGraduacao(respJSON[0]?.graduacao)
            setSituac_clube(respJSON[0]?.situac_clube)
            setRua(respJSON[0]?.socio_enderecos[0]?.logradouro)
            setNumero(respJSON[0]?.socio_enderecos[0]?.numero)
            setBairro(respJSON[0]?.socio_enderecos[0]?.bairro)
            setCidade(respJSON[0]?.socio_enderecos[0]?.cidade)
            setCep(respJSON[0]?.socio_enderecos[0]?.cep)
            setComplemento(respJSON[0]?.socio_enderecos[0]?.complemento)
            setEstado(respJSON[0]?.socio_enderecos[0]?.uf)

            setArrayDepen(respJSON[0]?.socio_dependentes)

            setPreloadRingMin('d-none')
            setBtnCadastrar('btn btn-primary mr-3 d-none')
            setBtnAtualizar('btn btn-success mr-3 d-block')

            setclassAlertBuscSocio2('d-none')
            setclassAlertBuscSocio('alert alert-success form-group col-md-12 d-block cadSucesso mt-3')
            setmsgAlert('Sócio encontrado, atualize os dados necessários logo abaixo.')
            return respJSON

        } catch (error) {
            setPreloadRingMin('d-none')
            setmsgAlert('Sócio ainda não cadastrado ou não encontrado.')
            setclassAlertBuscSocio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            setBtnCadastrar('btn btn-primary mr-3 d-block')
            setBtnAtualizar('btn btn-success mr-3 d-none')
            limparDados()
        }
    }

    function limparDados() {
        setNum_func('')
        setNome('')
        setSexo('')
        setRg('')
        setVinc_pm('')
        setCpf('')
        setDt_nasc('')
        setEstado_civil('')
        setSituac_efetivo('')
        setEmail('')
        setTel1('')
        setTel2('')
        setGraduacao('')
        setSituac_clube('')
        setRua('')
        setNumero('')
        setBairro('')
        setCidade('')
        setCep('')
        setComplemento('')
        setEstado('')
        setArrayDepen([])
        setNomeDepen('')
        setParentescoDepen('')
        setNascimentoDepen('')
        setclassAlertBuscSocio('alert alert-danger d-none')
        setclassAlertCampoVazio('alert alert-danger d-none')
        setBtnAtualizar('btn btn-success mr-3 d-none')
        setBtnCadastrar('btn btn-primary mr-3 d-block')
    }

    async function atualizarSocio() {
        setPreloadRingCadas('d-block mr-4')

        try {
            if (num_func !== '' && nome !== '' && sexo !== '' && rg !== '' & situac_clube !== ''
                && vinc_pm !== '' & cpf !== '' & dt_nasc !== '' & estado_civil !== ''
                && situac_efetivo !== '' && tel1 !== '' && graduacao !== '' && numero !== ''
                && rua !== '' && cidade !== '' && bairro !== '' && cep !== '' && estado !== '') {

                /*VALIDAÇÃO SIMPLES DE CPF, FUTURAMENTE INSERIR UMA VALIDAÇÃO MAIS FORTE */
                if (cpf.length !== 14) {
                    setPreloadRingCadas('d-none mr-4')
                    setmsgAlert(`Erro: CPF inválido.`)
                    return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
                }

                /*VALIDAÇÃO SIMPLES DE NF, VERIFICA SE O NF É MUITO GRANDE */
                if (num_func.length > 7) {
                    setPreloadRingCadas('d-none mr-4')
                    setmsgAlert(`Erro: Número de caracteres no NÚMERO FUNCIONAL é superior ao permitido.`)
                    return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
                }

                /*VALIDAÇÃO SIMPLES DE RG, VERIFICA SE O RG É MUITO GRANDE */
                if (rg.length > 7) {
                    setPreloadRingCadas('d-none mr-4')
                    setmsgAlert(`Erro: Número de caracteres no RG é superior ao permitido.`)
                    return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
                }

                //VERIFICAÇÃO PARA SO CHAMAR A FUNCAO atualizar CASO EXISTA ALGUM CAMPO A SER ATUALIZADO
                //CASO CONTRÁRIO JA VAI DIRETO PARA O PROXIMO SCRIPT
                if ((nome)?.toString() !== (copyDadosSocioBusc?.nome)?.toString() ||
                    (dt_nasc)?.toString() !== (copyDadosSocioBusc?.dt_nasc)?.toString() ||
                    (email)?.toString() !== (copyDadosSocioBusc?.email)?.toString() ||
                    (estado_civil)?.toString() !== (copyDadosSocioBusc?.estado_civil)?.toString() ||
                    (graduacao)?.toString() !== (copyDadosSocioBusc?.graduacao)?.toString() ||
                    (num_func)?.toString() !== (copyDadosSocioBusc?.num_func)?.toString() ||
                    (rg)?.toString() !== (copyDadosSocioBusc?.rg)?.toString() ||
                    (sexo)?.toString() !== (copyDadosSocioBusc?.sexo)?.toString() ||
                    (situac_clube)?.toString() !== (copyDadosSocioBusc?.situac_clube)?.toString() ||
                    (situac_efetivo)?.toString() !== (copyDadosSocioBusc?.situac_efetivo)?.toString() ||
                    (tel1)?.toString() !== (copyDadosSocioBusc?.tel1)?.toString() ||
                    (tel2)?.toString() !== (copyDadosSocioBusc?.tel2)?.toString() ||
                    (vinc_pm)?.toString() !== (copyDadosSocioBusc?.vinc_pm)?.toString() ||
                    (cpf)?.toString() !== (copyDadosSocioBusc?.cpf)?.toString() ||
                    (nomeViuva)?.toString() !== copyDadosSocioBusc?.nome_viuva ||
                    (cpfViuva)?.toString() !== copyDadosSocioBusc?.cpf_viuva) {

                    //SE O RETORNO FOR 0 OCORREU ALGUM ERRO.
                    //CASO CONTRARIO, SEGUE A TREAD    
                    if (await atualizarDadosPess(idSocio) === [0]) {
                        setPreloadRingCadas('d-none mr-4')
                        setmsgAlert('Ocorreu algum erro durante a atualização, tente novamente.')
                        return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
                    }
                }

                //VERIFICAÇÃO PARA SO CHAMAR A FUNCAO atualizar CASO EXISTA ALGUM CAMPO A SER ATUALIZADO
                //CASO CONTRÁRIO JA VAI DIRETO PARA O PROXIMO SCRIPT
                if ((rua)?.toString() !== (copyDadosSocioBusc?.socio_enderecos[0]?.logradouro)?.toString() ||
                    cep.toString() !== (copyDadosSocioBusc?.socio_enderecos[0]?.cep)?.toString() ||
                    (cidade)?.toString() !== (copyDadosSocioBusc?.socio_enderecos[0]?.cidade)?.toString() ||
                    estado.toString() !== (copyDadosSocioBusc?.socio_enderecos[0]?.uf)?.toString() ||
                    (bairro)?.toString() !== (copyDadosSocioBusc?.socio_enderecos[0]?.bairro)?.toString() ||
                    numero.toString() !== (copyDadosSocioBusc?.socio_enderecos[0]?.numero)?.toString() ||
                    (complemento)?.toString() !== (copyDadosSocioBusc?.socio_enderecos[0]?.complemento)?.toString()) {

                    //SE O RETORNO FOR 0 OCORREU ALGUM ERRO.
                    //CASO CONTRARIO, SEGUE A TREAD    
                    if (await atualizarEnderecoSocio(idSocio) === [0]) {
                        setPreloadRingCadas('d-none mr-4')
                        setmsgAlert('Ocorreu algum erro durante a atualização do endereço, tente novamente.')
                        return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
                    }
                }

                await removeDepenBD(idSocio)
                await addDepenBD(idSocio)

                setPreloadRingCadas('d-none mr-4')
                setmsgAlert('Atualização realizada com sucesso!')
                return setclassAlertCampoVazio('alert alert-success form-group col-md-12 mt-3 d-block cadSucesso')

            } else {
                setPreloadRingCadas('d-none mr-4')
                setmsgAlert('Existem campos em branco, favor preenche-los.')
                return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }

        } catch (error) {
            console.log('Error: ', error)
            setPreloadRingCadas('d-none mr-4')
            setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            setmsgAlert('Erro interno, tente novamente')
        }

    }

    async function atualizarDadosPess(idSocio) {
        setPreloadRingCadas('d-block mr-4')

        try {
            let nome_viuva
            let cpf_viuva

            //LIMPAR OS DADOS DA VIUVA CASO O MILITAR NÃO SEJA FALECIDO
            //OU ATRIBUIR OS STATES SE FOR FALECIDO
            if (situac_clube !== 'FALECIDO') {
                nome_viuva = ''
                cpf_viuva = ''
            } else {
                nome_viuva = nomeViuva
                cpf_viuva = cpfViuva
            }

            const response = await fetch(`${baseURL}socios/?id=${idSocio}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    num_func: Number(num_func),
                    rg: Number(rg),
                    nome: nome?.toUpperCase(),
                    cpf: cpf?.toUpperCase(),
                    email: email?.toUpperCase(),
                    sexo: sexo?.toUpperCase(),
                    vinc_pm,
                    dt_nasc: dt_nasc?.toUpperCase(),
                    graduacao: graduacao?.toUpperCase(),
                    estado_civil: estado_civil?.toUpperCase(),
                    situac_efetivo: situac_efetivo?.toUpperCase(),
                    situac_clube: situac_clube?.toUpperCase(),
                    tel1: tel1,
                    tel2: tel2,
                    nome_viuva, cpf_viuva

                })
            })

            const respJSON = await response.json()
            return respJSON

        } catch (error) {
            console.log('Error: ', error)
            setPreloadRingCadas('d-none mr-4')
            setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            setmsgAlert('Erro interno, tente novamente')
        }
    }

    async function atualizarEnderecoSocio(idSocio) {

        try {


            const response = await fetch(`${baseURL}socio/${idSocio}/edit-endereco`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    cep: cep?.toUpperCase(),
                    uf: estado?.toUpperCase(),
                    cidade: cidade?.toUpperCase(),
                    bairro: bairro?.toUpperCase(),
                    logradouro: rua?.toUpperCase(),
                    complemento: complemento?.toUpperCase(),
                    situacao: 'ATIVO',
                    numero: numero
                })
            })
            const respJSON = await response.json()
            return respJSON

        } catch (error) {
            console.log('Error: ', error)
            setPreloadRingCadas('d-none mr-4')
            setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            setmsgAlert('Erro interno, tente novamente')
        }
    }

    async function addDepenBD(idSocio) {

        //CADASTRO DOS DEPENDENTES DO SOCIO PELO ID
        arrayDepen.map(async (depen) => {

            const responseDepen = await fetch(`${baseURL}socio/${idSocio}/dependente`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    nome: (depen.nome)?.toUpperCase(),
                    parentesco: (depen.parentesco)?.toUpperCase(),
                    dt_nascimento: depen.dt_nascimento
                })
            })

            const respDepenJSON = await responseDepen.json()

            if (respDepenJSON.err) {
                setPreloadRingCadas('d-none mr-4')
                setmsgAlert(respDepenJSON.erro)
                setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }

            return respDepenJSON

        })

    }

    async function removeDepenBD(idSocio) {


        const response = await fetch(`${baseURL}socio/${idSocio}/remove-dependente`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                "authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        const respJSON = await response.json()
        return respJSON
    }

    /*******************************
    **RETORNO COMPONENTE PRINCIPAL**
    *******************************/
    return (
        <>
            <div className="container-fluid  rounded-lg border border-danger mb-3 pt-3 ">
                <div className="row justify-content-start ">
                    <div className="col-md-auto mr-n4"> <h5>Buscar Sócio</h5> </div>
                    <div className="col-md-auto"><i className={preloadRingMin} ><PreloaderRingMin /></i></div>
                </div>
                <div className="input-group my-3">
                    <div className="input-group-prepend">
                        <button onClick={buscarSocio} type="button" className="btn btn-outline-secondary">Buscar</button>
                        <button type="button" className="btn btn-outline-secondary  " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-sort-down"></i>
                        </button>
                        <div className="dropdown-menu" onClick={() => setDataBuscaSocio('')}>
                            <button onClick={() => setPlaceInputBusc('Número Funcional:')} className="dropdown-item" >NF</button>
                            <button onClick={() => setPlaceInputBusc('Nome:')} className="dropdown-item" >Nome</button>
                            <button onClick={() => setPlaceInputBusc('CPF:')} className="dropdown-item" >CPF</button>
                            <button onClick={() => setPlaceInputBusc('Nome Viúva:')} className="dropdown-item" >Nome Viúva</button>
                        </div>
                    </div>
                    <input id="buscarSocios" type="text" value={dataBuscaSocio}
                        onChange={event => setDataBuscaSocio(event.target.value)}
                        className="form-control"
                        aria-label="Text input with segmented dropdown button"
                        placeholder={placeInputBusc}
                        onClick={() => {
                            setclassAlertBuscSocio('alert alert-danger d-none')
                            setclassAlertCampoVazio('alert alert-danger d-none')
                        }}
                    />
                </div>
                <div className={classAlertBuscSocio} role="alert" >
                    <strong> {msgAlert}</strong>
                </div>
                <div className={classAlertBuscSocio2} role="alert" >
                    <strong> {msgAlert}</strong>
                </div>
            </div>

            <div className="container-fluid p-3 rounded-lg border border-danger mb-3">
                <h5>Dados Pessoais</h5>
                <form className="needs-validation">
                    <div className="form-row border-bottom  border-danger mb-3 pb-3">

                        <div className="form-group col-md-3">
                            <label htmlFor="nf">NF<span className="text-danger" >*</span></label>
                            <input required id="nf" type="number" className="form-control" placeholder=""
                                value={num_func} onChange={event => setNum_func(event.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="nome">Nome<span className="text-danger" >*</span></label>
                            <input required id="nome" type="text" className="form-control" placeholder=""
                                maxLength="50" value={nome} onChange={event => setNome(event.target.value)} />
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="rg">RG<span className="text-danger" >*</span></label>
                            <input required id="rg" type="number" className="form-control" placeholder="" value={rg} onChange={event => setRg(event.target.value)} />
                        </div>
                        <div className="form-group col-md-1">
                            <label htmlFor="inputEstado">Sexo<span className="text-danger" >*</span></label>
                            <select id="inputEstado" className="form-control" value={sexo} onChange={event => setSexo(event.target.value)} >
                                <option value="">-</option>
                                <option value="M">M</option>
                                <option value="F">F</option>
                            </select>
                        </div>
                        <div className="form-group col-md-1">
                            <label htmlFor="vinc_pm">Vínculo<span className="text-danger" >*</span>   </label>
                            <input required id="vinc_pm" type="number" className="form-control" placeholder="N°" value={vinc_pm} onChange={event => setVinc_pm(event.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="cpf">CPF<span className="text-danger " >*</span></label>
                            <input required id="cpf" type="text" className="form-control" placeholder="XXX.XXX.XXX-XX"
                                maxLength="14" value={cpf} onChange={event => setCpf(event.target.value)} />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="dt_nasc">Nascimento<span className="text-danger" >*</span></label>
                            <input required id="dt_nasc" type="date" className="form-control" placeholder="DD/MM/AAAA" value={dt_nasc} onChange={event => setDt_nasc(event.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="inputEstado_civ">Estado Civil<span className="text-danger" >*</span></label>
                            <select id="inputEstado_civ" className="form-control" value={estado_civil} onChange={event => setEstado_civil(event.target.value)}>
                                <option value="" >Escolher...</option>
                                <option value="CASADO (A)" >Casado (a)</option>
                                <option value="DIVORCIADO (A)" >Divorciado (a)</option>
                                <option value="SOLTEIRO (A)" >Solteiro (a)</option>
                                <option value="VIUVO (A)" >Viuvo (a)</option>
                                <option value="OUTROS" >Outros</option>
                                <option value="MARIATAL" >Mariatal</option>
                                <option value="NÃO INFORMA" >Não Informa</option>
                            </select>
                        </div>

                        <div className="form-group col-md-3">
                            <label htmlFor="situac_efetivo">Situação Vínculo Militar<span className="text-danger" >*</span></label>
                            <select id="situac_efetivo" className="form-control" value={situac_efetivo} onChange={event => setSituac_efetivo(event.target.value)}>
                                <option value="">Escolher...</option>
                                <option value="ATIVA" >Ativa</option>
                                <option value="REFORMADO (A)" >Reformado (a)</option>
                                <option value="RESERVA" >Reserva</option>
                                <option value="FALECIDO">Falecido</option>
                            </select>
                        </div>
                        <div className="form-group col-md-5">
                            <label htmlFor="email">E-mail</label>
                            <input id="email" type="email" className="form-control" placeholder="" value={email} onChange={event => setEmail(event.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="telefone1">Telefone<span className="text-danger" >*</span></label>
                            <input required id="telefone1" type="text" className="form-control" placeholder="(XX) XXXXX-XXXX"
                                value={tel1}
                                onChange={event => setTel1(event.target.value)} />
                        </div>
                        <div className="form-group col-md-5">
                            <label htmlFor="telefone2">Telefone</label>
                            <input id="telefone2" type="text" className="form-control" placeholder="(XX) XXXXX-XXXX" value={tel2} onChange={event => setTel2(event.target.value)} />
                        </div>
                        <div className="form-group col-md-5">
                            <label htmlFor="graduacao">Posto / Graduação<span className="text-danger" >*</span></label>
                            <select id="graduacao" className="form-control" value={graduacao} onChange={event => setGraduacao(event.target.value)} >
                                <option value="">Escolher...</option>
                                <option value={'CORONEL'} >Coronel</option>
                                <option value={'TENENTE-CORONEL'} >Tenente-Coronel</option>
                                <option value={'MAJOR'} >Major</option>
                                <option value={'CAPITÃO'} >Capitão</option>
                                <option value={'1º TENENTE'} >1º Tenente</option>
                                <option value={'2º TENENTE'} >2º Tenente</option>
                                <option value={'SUB TENENTE'} >Sub Tenente</option>
                                <option value={'ALUNO OFICIAL'} >Aluno Oficial</option>
                                <option value={'1º SARGENTO'} >1º Sargento</option>
                                <option value={'2º SARGENTO'} >2º Sargento</option>
                                <option value={'3º SARGENTO'} >3º Sargento</option>
                                <option value={'CABO'} >Cabo</option>
                                <option value={'SOLDADO'} >Soldado</option>
                                <option value={'ALUNO SOLDADO'} >Aluno Soldado</option>
                                <option value={graduacao} >{graduacao}</option>
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="situac_clube">Situação no Clube<span className="text-danger" >*</span> </label>
                            <select id="situac_clube" className="form-control" value={situac_clube} onChange={event => setSituac_clube(event.target.value)} >
                                <option value="">Escolher...</option>
                                <option value="NORMAL">NORMAL</option>
                                <option value="INATIVO">INATIVO</option>
                                <option value="FALECIDO">FALECIDO</option>
                                <option value="INADIMPLENTE">INADIMPLENTE</option>
                            </select>
                        </div>

                    </div>

                    <div className={situac_clube === 'FALECIDO' ? " m-0 p-0" : " m-0 p-0 d-none"}>
                        <h5>Viuvo (a)</h5>

                        <div className="form-row border-bottom mb-3 pb-3 border-danger">

                            <div className="form-group col-md-8">
                                <label htmlFor="nomeViuv">Nome</label>
                                <input required id="nomeViuv" type="text" className="form-control" value={nomeViuva !== null ? nomeViuva : ''}
                                    onChange={event => setNomeViuva(event.target.value)} maxLength="50" />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="cpfViuv">CPF</label>
                                <input required id="cpfViuv" type="text" className="form-control" placeholder="XXX.XXX.XXX-XX"
                                    value={cpfViuva !== null ? cpfViuva : ''} maxLength="14" onChange={event => setCpfViuva(event.target.value)} />
                            </div>

                        </div>
                    </div>

                    <h5>Endereço</h5>


                    <div className="form-row border-bottom mb-3 pb-3 border-danger">

                        <div className="form-group col-md-10">
                            <label htmlFor="rua">Logradouro<span className="text-danger" >*</span></label>
                            <input required id="rua" type="text" className="form-control" placeholder=""
                                maxLength="60" value={rua} onChange={event => setRua(event.target.value)} />
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="numero">N°<span className="text-danger" >*</span></label>
                            <input required type="number" id="numero" className="form-control" placeholder="" value={numero} onChange={event => setNumero(event.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="bairro">Bairro<span className="text-danger" >*</span></label>
                            <input required type="text" id="bairro" className="form-control" placeholder="" value={bairro} onChange={event => setBairro(event.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="cidade">Município<span className="text-danger" >*</span></label>
                            <input required type="text" id="cidade" className="form-control" placeholder="" value={cidade} onChange={event => setCidade(event.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="uf">UF<span className="text-danger" >*</span></label>
                            <select id="uf" className="form-control" value={estado} onChange={event => setEstado(event.target.value)}>
                                <option>Escolher...</option>
                                <option value="">Selecione</option>
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AP">Amapá</option>
                                <option value="AM">Amazonas</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="DF">Distrito Federal</option>
                                <option value="ES">Espirito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PR">Paraná</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="RO">Rondônia</option>
                                <option value="RR">Roraima</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SP">São Paulo</option>
                                <option value="SE">Sergipe</option>
                                <option value="TO">Tocantins</option>
                            </select>
                        </div>

                        <div className="form-group col-md-3">
                            <div className="row justfy-content-start">
                                <div className="col-md-auto mr-n4"> <label htmlFor="cep">CEP<span className="text-danger" >*</span> </label> </div>
                                <div className={preloadRingMinCep}>  <PreloaderRingMin /> </div>
                            </div>
                            <div className="input-group ">

                                <input required id="cep" onClick={() => setclassAlert('alert alert-danger form-group col-md-12 d-none')}
                                    type="text" className="form-control" value={cep}
                                    placeholder="Apenas números" aria-label="Recipient's username"
                                    aria-describedby="button-addon2" onChange={(data) => setCep(data.target.value)}
                                />

                                <div className="input-group-append">
                                    <button onClick={() => buscarCep()} className="btn btn-outline-secondary" type="button" id="button-addon2">Buscar</button>
                                </div>

                            </div>
                        </div>

                        <div className="form-group col-md-9">
                            <label htmlFor="complemento">Complemento</label>
                            <input id="complemento" type="text" className="form-control" placeholder=""
                                maxLength="60" value={complemento} onChange={event => setComplemento(event.target.value)} />
                        </div>

                        <div className={classAlert} role="alert" >
                            {msgAlert}
                        </div>

                    </div>

                    <h5>Dependentes
                        <button type="button" onClick={addDepend} className="btn btn-success mx-2" > <i className="fas fa-user-plus"></i> </button>
                    </h5>

                    <div className="border-bottom mb-3 pb-3 border-danger">

                        <div className="form-row">
                            <div className="form-group col-md-7">
                                <label htmlFor="nomeDepen">Nome</label>
                                <input onClick={() => setclassAlertDepen('alert alert-danger form-group col-md-12 mt-2 d-none')}
                                    type="text" id="nomeDepen" className="form-control" placeholder="" maxLength="50"
                                    value={nomeDepen} onChange={event => setNomeDepen(event.target.value)} />
                            </div>

                            <div className="form-group col-md-2">
                                <label htmlFor="nascimentoDepen">Nascimento</label>
                                <input onClick={() => setclassAlertDepen('alert alert-danger form-group col-md-12 mt-2 d-none')}
                                    id="nascimentoDepen" type="date" className="form-control" placeholder="DD/MM/AAAA"
                                    value={nascimentoDepen} onChange={event => setNascimentoDepen(event.target.value)} />
                            </div>

                            <div className="form-group col-md-3">
                                <label htmlFor="parentesco">Parentesco</label>
                                <select onClick={() => setclassAlertDepen('alert alert-danger form-group col-md-12 mt-2 d-none')}
                                    id="parentesco" className="form-control" value={parentescoDepen}
                                    onChange={event => setParentescoDepen(event.target.value)}>
                                    <option >Escolher...</option>
                                    <option>Avô (ó)</option>
                                    <option>Esposo (a)</option>
                                    <option>Filho (a)</option>
                                    <option>Irmão (a)</option>
                                    <option>Mâe</option>
                                    <option>Pai</option>
                                </select>
                            </div>
                        </div>

                        <div className={classAlertDepen} role="alert" >
                            <strong> {msgAlert}</strong>
                        </div>

                        <div className="col" id="insertDepen">
                            <div style={{ overflow: 'auto' }} >

                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Cod.</th>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Nascimento</th>
                                            <th scope="col">Parentesco</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {arrayDepen.map(depen => {

                                            //formatar da data
                                            let dataNasc = (depen.dt_nascimento)?.toString()
                                            let dia, mes, ano
                                            dia = dataNasc.substring(8, 10)
                                            mes = dataNasc.substring(5, 7)
                                            ano = dataNasc.substring(0, 4)
                                            dataNasc = `${dia}/${mes}/${ano}`

                                            return (
                                                <tr key={depen.id} id="linhaDepen">
                                                    <td>{depen.id}</td>
                                                    <td>{depen.nome}</td>
                                                    <td>{dataNasc}</td>
                                                    <td>{depen.parentesco}</td>
                                                    <td>   <button type="button" onClick={() => removeDepen(depen.id)} className="btn btn-danger mx-0 my-0" > <i className="fas fa-user-minus"></i> </button> </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>

                        </div>

                    </div>

                    <div className="col-md-8 mb-4">
                        <div className={classAlertCampoVazio} role="alert" >
                            <strong>  {msgAlert} </strong>
                        </div>
                    </div>


                    <div className="form-row justify-content-end">
                        <i className={preloadRingCadas}> <PreloaderRing /> </i>
                        <button
                            onClick={() => {
                                setclassAlertCampoVazio('d-none')
                                setBtnCadastrarModal('btn btn-primary d-none')
                                setBtnAtualizarModal('btn btn-success d-block')
                            }}
                            type="button"
                            className={btnAtualizar}
                            data-toggle="modal"
                            data-target="#exampleModal"
                        >Atualizar</button>

                        <button
                            onClick={() => {
                                setclassAlertCampoVazio('d-none')
                                setBtnCadastrarModal('btn btn-primary d-block')
                                setBtnAtualizarModal('btn btn-success d-none')
                            }}
                            type="button"
                            className={btnCadastrar}
                            data-toggle="modal"
                            data-target="#exampleModal"
                        >Cadastrar</button>

                        <button onClick={limparDados} className="btn btn-danger" type="button">Limpar</button>
                    </div>
                </form>
            </div>


            {/*MODAL DE CONFIRMAÇÃO DOS DADOS*/}

            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> <strong> CONFIRME OS DADOS ABAIXO </strong></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4> Dados Pessoais:</h4>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <strong>NOME:</strong> {nome} <br />
                                        <strong>NF:  </strong> {num_func} <br />
                                        <strong>RG:  </strong> {rg} <br />
                                        <strong>SEXO:  </strong> {sexo} <br />
                                        <strong>E-MAIL:  </strong> {email} <br />
                                        <strong>TELEFONE:  </strong> {tel1} <br />
                                        <strong>TELEFONE:  </strong> {tel2} <br />

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <strong>CPF:</strong> {cpf} <br />
                                        <strong>VÍNCULO:</strong> {vinc_pm} <br />
                                        <strong>NASCIMENTO:  </strong> {dt_nasc} <br />
                                        <strong>ESTADO CIVIL:  </strong> {estado_civil} <br />
                                        <strong>POSTO / GRADUAÇÃO:  </strong> {graduacao} <br />
                                        <strong>SITUAÇÃO NO CLUBE:  </strong> {situac_clube} <br />
                                        <strong>SITUAÇÃO MILITAR:  </strong> {situac_efetivo} <br />
                                    </div>

                                    <div className="pt-3 col-md-12 border-top border-dark">
                                        <h4> Endereço:</h4>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <strong>LOGRADOURO:</strong> {rua} <br />
                                        <strong>BAIRRO:  </strong> {bairro} <br />
                                        <strong>UF:  </strong> {cidade} <br />
                                        <strong>COMPLEMENTO:  </strong> {complemento} <br />

                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <strong>N°:</strong> {numero} <br />
                                        <strong>MUNICÍPIO:</strong> {bairro} <br />
                                        <strong>CEP:  </strong> {cep} <br />
                                    </div>

                                    <div className="pt-3 col-md-12 mb-3 border-top border-dark">
                                        <h4>Depedentes:</h4>
                                    </div>
                                    <div className="col-md-12">

                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Nascimento</th>
                                                    <th scope="col">Parentesco</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {arrayDepen.map(depen => {

                                                    //formatar da data
                                                    let dataNasc = (depen.dt_nascimento)?.toString()
                                                    let dia, mes, ano
                                                    dia = dataNasc.substring(8, 10)
                                                    mes = dataNasc.substring(5, 7)
                                                    ano = dataNasc.substring(0, 4)
                                                    dataNasc = `${dia}/${mes}/${ano}`

                                                    return (
                                                        <tr key={depen.id} >
                                                            <td>{depen.nome}</td>
                                                            <td>{dataNasc}</td>
                                                            <td>{depen.parentesco}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Retornar</button>
                            <button onClick={salvarDadosBD} type="button" className={btnCadastrarModal} data-dismiss="modal">Savar</button>
                            <button onClick={atualizarSocio} type="button" className={btnAtualizarModal} data-dismiss="modal">Savar Atualização</button>
                        </div>
                    </div>
                </div>
            </div>
            {/**
             *FIM DO MODAL 
             */}
        </>
    )
} 