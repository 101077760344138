import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './AlterarSenha.css'
import { baseURL } from '../../../config/baseURL'
import { PreloaderRingMin } from '../../../components/Preloder/Preloader'

export default function AlterarSenha() {

    const dispath = useDispatch()
    dispath({ type: 'UPDATE_ALTERAR_SENHA' })
    const [idSocio, setIdSocio] = useState('')
    const [num_func, setNum_func] = useState('')
    const [nome, setNome] = useState('')
    const [alertUserSenha, setAlertUserSenha] = useState('alert alert-danger col-md-12 mt-2 d-none mb-n3')
    const [msgErro, setMsgErro] = useState('')
    const [displayPreload, setDisplayPreload] = useState("my-auto ml-2 d-none")
    const [displayPreload2, setDisplayPreload2] = useState("my-auto ml-2 d-none")
    const [senha, setSenha] = useState('')
    const [checked, setChecked] = useState(true)
    const [repetSenha, setRepetSenha] = useState('')

    function limparDados() {
        setNome('')
        setNum_func('')
        setSenha('')
        setRepetSenha('')
    }

    async function buscarSocioBD() {
        //setAlertUserSenha('d-none')
        setDisplayPreload2("my-auto ml-2 ")

        try {

            if (num_func.length === 0) {
                setDisplayPreload2('d-none')
                setMsgErro('Preencha o Número Funcional para realizar a pesquisa.')
                return setAlertUserSenha('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }


            const response = await fetch(`${baseURL}socio?num_func=${num_func}`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    "authorization": `Bearer ${localStorage.getItem('token')}`
                }
            })

            const respJSON = await response.json()

            if (respJSON.erro) {
                setDisplayPreload2('d-none')
                limparDados()
                setAlertUserSenha('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
                return setMsgErro('Sócio não encontrado.')
            }


            setIdSocio(respJSON[0].id)
            setNum_func(respJSON[0].num_func)
            setNome(respJSON[0].nome)


            setAlertUserSenha('alert alert-success form-group col-md-12 d-block cadSucesso mt-3')
            setMsgErro('Sócio encontrado, atualize a senha.')
            setDisplayPreload2('d-none')
            return respJSON

        } catch (error) {
            setDisplayPreload2('d-none')
            setMsgErro('Sócio não encontrado.')
            setAlertUserSenha('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            limparDados()
        }
    }

    async function enviarNovaSenha(event) {
        event.preventDefault()
        setDisplayPreload("my-auto ml-2")

        if (senha !== repetSenha) {
            setDisplayPreload("my-auto ml-2 d-none")
            setMsgErro('A senha e a confirmação devem ser iguais.')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-2 mb-n3')
        }

        if (senha.length === 0 || repetSenha.length === 0
            || nome.length === 0 || num_func.length === 0) {
            setDisplayPreload("my-auto ml-2 d-none")
            setMsgErro('Existem campos em branco.')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-2 mb-n3')
        }

        const response = await fetch(`${baseURL}socio-update-senha?id=${idSocio}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                senha,
                force_senha: checked ? 'S' : 'N'
            })
        })

        const resJSON = await response.json()

        if (resJSON === 0) {
            setDisplayPreload("my-auto ml-2 d-none")
            setMsgErro('Ocorreu algum erro durante a atualizaçao, tente mais tarde.')
            return setAlertUserSenha('alert alert-danger col-md-12 mt-2 mb-n3')
        }

        setDisplayPreload("my-auto ml-2 d-none")
        setMsgErro('Senha alterada com sucesso!')
        setAlertUserSenha('alert alert-success col-md-12 mt-2 mb-n3')

    }

    return (
        <div className="container">

            <div className="row  justify-content-center">

                <div className="col-md-8 py-5 px-3 rounded-lg border border-danger">

                    <form onSubmit={enviarNovaSenha} >
                        <div className="form-row">

                            <div className=" col-md-12 mb-3">


                                <div className="row ml-0">
                                    <label className="mb-2" htmlFor="nome"> <strong> Número Funcional </strong></label>
                                    <div className={displayPreload2}>  <PreloaderRingMin /> </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <button type="button" className="input-group-text text-light" style={{ backgroundColor: '#700909' }}
                                            onClick={buscarSocioBD} id="basic-addon1"> <i className="fas fa-search"></i> </button>
                                    </div>
                                    <input type="text" className="form-control" id="numFuncional"
                                        ria-describedby="basic-addon1" value={num_func}
                                        onChange={event => setNum_func(event.target.value)}
                                        onClick={() => setAlertUserSenha('d-none')} required />
                                </div>
                                <small id="emailHelp" className="form-text text-muted">Pesquise o NF do militar para alterar sua senha.</small>
                            </div>

                            <div className=" col-md-12 mb-3">
                                <label className="mb-2" htmlFor="nome"> <strong> Nome Completo </strong></label>
                                <input disabled style={{ borderLeftStyle: 'solid', borderLeftWidth: '3px', borderLeftColor: '#700909' }}
                                    type="text" className="form-control" id="nome"
                                    ria-describedby="basic-addon1" value={nome} required />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label className="mb-2" htmlFor="senha"> <strong> Nova Senha </strong></label>
                                <input style={{ borderLeftStyle: 'solid', borderLeftWidth: '3px', borderLeftColor: '#700909' }}
                                    type="password" className="form-control" id="senha" value={senha}
                                    onChange={event => setSenha(event.target.value)}
                                    onClick={() => setAlertUserSenha('d-none')} required />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="mb-2" htmlFor="reptSenha"> <strong> Confirmar Nova Senha </strong></label>
                                <input style={{ borderLeftStyle: 'solid', borderLeftWidth: '3px', borderLeftColor: '#700909' }}
                                    type="password" className="form-control" id="reptSenha" value={repetSenha}
                                    onChange={event => setRepetSenha(event.target.value)}
                                    onClick={() => setAlertUserSenha('d-none')} required />
                            </div>

                            <div className="col-md-12 mb-3">
                                <div className=" custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" checked={checked}
                                        onClick={() => checked ? setChecked(false) : setChecked(true)}
                                        onChange={() => { }}
                                    />
                                    <label className="custom-control-label" htmlFor="customCheck1">Forçar alteração de senha?</label>
                                </div>
                            </div>

                        </div>


                        <div className="row mx-0">
                            <button type="submit" style={{ backgroundColor: '#700909', }} className="btn btn-danger my-2" >Salvar</button>
                            <button type="button" onClick={limparDados} className="btn btn-secondary my-2 mx-2" >Limpar</button>
                            <div className={displayPreload}>  <PreloaderRingMin /> </div>

                        </div>

                    </form>

                    <div className={alertUserSenha} role="alert">
                        <strong> {msgErro} </strong>
                    </div>

                </div>

            </div>

        </div>


    )
}