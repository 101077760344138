import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import LogoCaxias from '../../../../assets/img/pngCaxiasLogo.png'
import { PreloaderRingMin } from '../../../../components/Preloder/Preloader'
import { baseURL } from '../../../../config/baseURL'


export default function Relacao_Socios() {


    const dispatch = useDispatch()
    dispatch({ type: 'UPDATE_RELAC_SOCIOS' })

    const [preloadRingMin, setPreloadRingMin] = useState('d-none')
    const [sexo, setSexo] = useState('TODOS')
    const [graduacao, setGraduacao] = useState('TODOS')
    const [situacClub, setSituacClub] = useState('TODOS')
    const [ordemRel, setOrdemRel] = useState('nome')
    const [arrayDados, setArrayDados] = useState([])
    const [areaFiltro, setAreaFiltro] = useState('container-fluid  rounded-lg border border-danger mb-3 pt-3')
    const [areaLogo, setAreaLogo] = useState('row align-items-center justify-content-center d-none')
    const [areaDadosRel, setAreaDadosRel] = useState('container-fluid  rounded-lg border border-danger mb-3 pt-3')
    const [classAlertBuscSocio, setclassAlertBuscSocio] = useState('alert alert-danger form-group col-md-12 mt-5 d-none')
    const [msgAlert, setmsgAlert] = useState('')
    const [btnImprimir, setBtnImprimir] = useState('btn btn-dark mx-3 d-none')

    async function gerarRelatorio() {
        setPreloadRingMin('d-block')
        setBtnImprimir('btn btn-dark mx-3 d-none')
        setArrayDados([])

        const response = await fetch(baseURL + 'rel-socios-filter', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                sexo: sexo,
                graduacao: graduacao,
                situacClub: situacClub,
                ordemRel: ordemRel
            })
        })
        const respJSON = await response.json()

        if (respJSON.erro) {
            setPreloadRingMin('d-none')
            setmsgAlert(respJSON.erro)
            return setclassAlertBuscSocio('mt-n2 alert alert-danger form-group col-md-6 mt-5 d-block text-left')
        }

        setBtnImprimir('btn btn-dark mx-3')
        setPreloadRingMin('d-none')
        setmsgAlert('Relatório gerado com sucesso.')
        setclassAlertBuscSocio('mt-n2 alert alert-success form-group col-md-6 mt-5 d-block text-left')
        //   setAreaFiltro('container-fluid  rounded-lg border border-danger mb-3 pt-3 d-none')
        setArrayDados(respJSON)
    }

    async function imprimirRel() {
        await dispatch({ type: 'UPDATE_DISPLAY_N' })
        await setAreaFiltro('container-fluid  rounded-lg border border-danger mb-3 pt-3 d-none')
        await setAreaLogo('row align-items-center justify-content-center')
        await setAreaDadosRel('container-fluid  rounded-lg mb-3 pt-3')

        window.print()

        await dispatch({ type: 'UPDATE_DISPLAY_B' })
        await setAreaLogo('row align-items-center justify-content-center d-none')
        await setAreaFiltro('container-fluid  rounded-lg border border-danger mb-3 pt-3')
        await setAreaDadosRel('container-fluid  rounded-lg border border-danger mb-3 pt-3')
    }

    return (
        <div className="container-fluid text-center mt-n3">
            <div className={areaLogo}>
                <div className="col-md-2 ">
                    <img src={LogoCaxias} className='ml-5 img-fluid' alt="" />
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>CAXIAS ESPORTE CLUBE</h1>
                        </div>
                        <div className="col-md-12">
                            <h4>FUNDADO EM 06 DE SETEMBRO DE 1940</h4>
                        </div>
                    </div>
                </div>
            </div>

            {/*AREA DOS FILTROS*/}
            <div className={areaFiltro}>
                <div className="row justify-content-start mb-3">
                    <div className="col-md-auto mr-n4"> <h5>Filtros do Relatório</h5> </div>
                    <div className="col-md-auto"><i className={preloadRingMin} ><PreloaderRingMin /></i></div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-3 text-left">
                        <label htmlFor="ordemRel">Ordem da Relação</label>
                        <select id="ordemRel" className="form-control" value={ordemRel} onChange={event => setOrdemRel(event.target.value)} >
                            <option value="nome">ALFABÉTICA</option>
                            <option value="num_func">NF</option>
                            <option value="rg">RG</option>
                        </select>
                    </div>
                    <div className="form-group col-md-2 text-left mx-3">
                        <label htmlFor="sexo">Sexo</label>
                        <select id="sexo" className="form-control" value={sexo} onChange={event => setSexo(event.target.value)} >
                            <option value="TODOS">TODOS</option>
                            <option value="M">M</option>
                            <option value="F">F</option>
                        </select>
                    </div>
                    <div className="form-group col-md-3 text-left mx-3">
                        <label htmlFor="situacClub">Situação no Clube</label>
                        <select id="situacClub" className="form-control" value={situacClub} onChange={event => setSituacClub(event.target.value)} >
                            <option value="TODOS">TODOS</option>
                            <option value="NORMAL">NORMAL</option>
                            <option value="INATIVO">INATIVOS</option>
                            <option value="INADIMPLENTE">INADIMPLENTES</option>
                            <option value="FALECIDO">FALECIDOS</option>
                        </select>
                    </div>
                    <div className="form-group col-md-3">
                        <label htmlFor="graduacao">Posto / Graduação<span className="text-danger" >*</span></label>
                        <select id="graduacao" className="form-control" value={graduacao} onChange={event => setGraduacao(event.target.value)} >
                            <option value="TODOS">TODOS</option>
                            <option value={'CORONEL'} >Coronel</option>
                            <option value={'TENENTE-CORONEL'} >Tenente-Coronel</option>
                            <option value={'MAJOR'} >Major</option>
                            <option value={'CAPITÃO'} >Capitão</option>
                            <option value={'1º TENENTE'} >1º Tenente</option>
                            <option value={'2º TENENTE'} >2º Tenente</option>
                            <option value={'SUB TENENTE'} >Sub Tenente</option>
                            <option value={'ALUNO OFICIAL'} >Aluno Oficial</option>
                            <option value={'1º SARGENTO'} >1º Sargento</option>
                            <option value={'2º SARGENTO'} >2º Sargento</option>
                            <option value={'3º SARGENTO'} >3º Sargento</option>
                            <option value={'CABO'} >Cabo</option>
                            <option value={'SOLDADO'} >Soldado</option>
                            <option value={'ALUNO SOLDADO'} >Aluno Soldado</option>
                        </select>
                    </div>
                    <div className={classAlertBuscSocio} role="alert" >
                        <strong> {msgAlert}</strong>
                    </div>
                    <div className="form-group col-md-12 text-left">
                        <button onClick={gerarRelatorio} className="btn btn-secondary" type="button">Gerar</button>
                        <button onClick={imprimirRel} className={btnImprimir} type="button">  <i className="fas fa-print"></i> </button>
                    </div>
                </div>
            </div>

            {/*AREA DOS DADOS DO RELATORIO*/}
            <div id="bodyBusc" className={areaDadosRel} style={{ overflow: 'auto' }} >
                <table className="table table-sm table-striped table-hover text-center" style={{ fontSize: '13px' }} >
                    <thead>
                        <tr>
                            <th scope="col">N°</th>
                            <th scope="col">NF</th>
                            <th scope="col">RG</th>
                            <th className="text-left" scope="col"><span className="ml-5">NOME</span></th>
                            <th scope="col">SEXO</th>
                            <th className="text-left" scope="col"><span className="ml-5"> POSTO / GRADUAÇÃO</span></th>
                            <th scope="col">SITUAÇÃO NO CLUBE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            arrayDados.map((socio) => {

                                //contador
                                let cont = arrayDados.findIndex((soc) => soc.num_func === socio.num_func) + 1

                                return (
                                    /*  NO SCRIPT ABAIXO USO O NF COMO PESQUISE DE ID */
                                    <tr data-toggle="modal" data-target="#exampleModal" key={socio.id}>
                                        <th id="contN" scope="row">{cont}</th>
                                        <td id={socio.num_func}> {socio.num_func}</td>
                                        <td > {socio.rg}</td>
                                        <td className="text-left"><span className="ml-5">{socio.nome}</span></td>
                                        <td>{socio.sexo}</td>
                                        <td className="text-left"> <span className="ml-5">{socio.graduacao}</span> </td>
                                        <td> {socio.situac_clube}</td>
                                    </tr>)
                            })
                        }
                    </tbody>
                </table>
            </div>





        </div>

    )

}