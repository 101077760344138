import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { baseURL } from '../../../../config/baseURL'
import saveAs from 'file-saver'
import './index.css'
import { PreloaderRing } from '../../../../components/Preloder/Preloader'


export default function AtaReuniao() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({ type: 'UPDATE_ATAS_REUNIOES' })
        getDadosAtaReuniao()
    }, [])

    const [data, setData] = useState('')
    const [assunto, setAssunto] = useState('')
    const [descricao, setDescricao] = useState('')
    const [preload, setPreload] = useState("p-1 d-none")
    const [arquivo, setArquivo] = useState([])
    const [placeInput, setPlaceInput] = useState('Arraste aqui ou clique em Browse')
    const [styleInput, setStyleInput] = useState("custom-file-label")
    const [alertDiv, setAlertDiv] = useState('alert alert-danger form-group col-md-12 mt-5 d-none')
    const [msgAlert, setmsgAlert] = useState('')
    const [numCharSmall, setNumCharSmall] = useState('Insira no máximo 150 caracteres.')
    const [styleSmall, setStyleSmall] = useState('form-text text-muted')
    const [arrayAtaReuniao, setArrayAtaReuniao] = useState([])
    const [opacity, setOpacity] = useState('none')

    async function getDadosAtaReuniao() {

        const response = await fetch(`${baseURL}ata-reuniao-index-all`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        const respJSON = await response.json()
        setArrayAtaReuniao(respJSON)
    }

    async function removerAtaReuniao(id, nome_arquivo) {
        setOpacity('1')

        const response = await fetch(`${baseURL}ata-reuniao-delete`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {

                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`

            },
            body: JSON.stringify({
                id,
                nome_arquivo
            })
        })

        if (response.status === 404 || response.status === 400 || response.status === 500
            || response.status === 506) {
            setOpacity('0')
            setmsgAlert('Erro de servidor, entre em contato com administrador do sistema.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        const respJSON = await response.json()

        if (respJSON.erro) {
            setOpacity('0')
            setmsgAlert(respJSON.erro + ' Entre em contato com o administrador do sistema.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        if (respJSON === 0) {
            setOpacity('0')
            setmsgAlert('Ocorreu algum erro interno, entre em contato com o administrador do sistema.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        setOpacity('0')
        setmsgAlert('Ata de Reunião removida com sucesso.')
        setAlertDiv('alert my-2 alert-success form-group col-md-12')
        getDadosAtaReuniao()
    }

    async function baixarAtaReuniao(nome_arquivo) {

        const response = await fetch(`${baseURL}ata-reuniao?nome_arquivo=${nome_arquivo}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        const blobFile = await response.blob()
        let fileName

        switch (blobFile.type) {
            case 'application/pdf':
                fileName = 'AtaReuniaoCaxias.pdf'
                break;
            case 'image/jpeg':
                fileName = 'AtaReuniaoCaxias.jpeg'
                break;
            case 'image/pjpeg':
                fileName = 'AtaReuniaoCaxias.pjpeg'
                break;
            case 'image/png':
                fileName = 'AtaReuniaoCaxias.png'
                break;
            default:
                fileName = 'AtaReuniaoCaxias'
                break;
        }
        saveAs(blobFile, fileName)
    }

    async function inserirAtaReuniao(event) {
        event.preventDefault();

        setPreload('p-1')

        if (assunto.length === 0 || data.length === 0 || descricao.length === 0) {
            setPreload('d-none')
            setmsgAlert('Existem campos em branco.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        if (descricao.length > 150) {
            setPreload('d-none')
            setmsgAlert('A descrição ultrapassou o número máximo de carateres permitidos. (Máximo 150 caracteres.)')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        if (arquivo.length === 0) {
            setPreload('d-none')
            setmsgAlert('Insira o arquivo da Ata de Reuniao.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        const dataArquivo = new FormData()
        dataArquivo.append('file', arquivo)
        dataArquivo.append('assunto', assunto)
        dataArquivo.append('descricao', descricao)
        dataArquivo.append('data', data)

        const response = await fetch(`${baseURL}ata-reuniao`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                "authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: dataArquivo,

        })

        if (response.status === 404 || response.status === 400 || response.status === 500
            || response.status === 401 || response.status === 506) {
            setOpacity('0')
            setmsgAlert('Erro de servidor, entre em contato com administrador do sistema.')
            return setAlertDiv('alert my-2 alert-danger form-group col-md-12')
        }

        setPreload('d-none')
        setmsgAlert('Ata de Reunião inserida com sucesso.')
        setAlertDiv('alert my-2 alert-success form-group col-md-12')
        getDadosAtaReuniao()
    }

    return (

        <div className="container">

            <div className="row  justify-content-center">
                <div className="col-md-11 py-2 px-3  rowPrincipal">
                    <div className="col-md-12 text-center">
                        <h3>Inserir Atas de Reuniões</h3>
                    </div>

                    <form onSubmit={inserirAtaReuniao} className="needs-validation">
                        <div className="form-group">
                            <label htmlFor="data"> <strong> Data da Ata </strong></label>
                            <input type="text" className="form-control" id="data"
                                value={data} onChange={event => setData(event.target.value)}
                                onClick={() => setAlertDiv('d-none')} required />
                            <small id="dataSmall" className="form-text text-muted">Insira a data da Ata ex: (10/10/2019 as 14:30h)</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="assunto"> <strong> Assunto </strong></label>
                            <input type="text" className="form-control" id="assunto"
                                value={assunto} onChange={event => setAssunto(event.target.value)}
                                onClick={() => setAlertDiv('d-none')} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao"> <strong> Descrição </strong></label>
                            <textarea id="descricao" className="form-control" aria-label="With textarea"
                                value={descricao} onChange={event => {
                                    setDescricao(event.target.value)
                                    setNumCharSmall('Número de caracteres: ' + (descricao.length + 1))
                                    descricao.length < 150 ? setStyleSmall('form-text text-success font-weight-bolder') : setStyleSmall('form-text text-danger font-weight-bolder')
                                }} ></textarea>
                            <small id="descricaoSmall" className={styleSmall}> {numCharSmall} </small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="customFile"> <strong> Insira o arquivo </strong></label>
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="customFile"
                                    onClick={() => setAlertDiv('d-none')}
                                    onChange={(event) => {
                                        if (event.target.files[0] !== undefined) {
                                            if (event.target.files[0].type !== 'application/pdf' && event.target.files[0].type !== 'image/png'
                                                && event.target.files[0].type !== 'image/pjpeg' && event.target.files[0].type !== 'image/jpeg') {
                                                setPreload('d-none')
                                                setPlaceInput('Formato de arquivo inválido.')
                                                return setStyleInput("custom-file-label text-danger")
                                            }
                                            setArquivo(event.target.files[0])
                                            event.target.files[0] !== undefined ? setPlaceInput(event.target.files[0].name) : setPlaceInput('Arraste aqui ou clique em Browse')
                                            setStyleInput("custom-file-label text-success")
                                        }

                                    }} />
                                <label className={styleInput} htmlFor="customFile"> {placeInput} </label>
                                <small id="smallArquivo" className="form-text text-muted">Insira aqui o arquivo PDF ou IMAGEM da Ata de Reuniao.</small>
                            </div>
                        </div>

                        <div className="row ml-2">
                            <button type="submit" className="btn btn-info  my-2">Inserir</button>
                            <button className="btn btnDownload m-2">Limpar</button>
                            <div className={preload}>  <PreloaderRing /> </div>
                        </div>

                        <div className={alertDiv} role="alert" >
                            <strong> {msgAlert}  </strong>
                        </div>

                        <div className="col-md-12 text-center mb-3">
                            <h3>Atas de Reuniões Cadastradas</h3>
                        </div>

                    </form>
                    <div className="col-md-12 mt-3 px-0" style={{ overflow: 'auto' }}>
                        <table className="table table-hover text-center" style={{ overflow: 'auto', borderStyle: 'solid', borderWidth: '5px', borderColor: 'rgb(124, 3, 3)' }}>
                            <thead>
                                <tr className="text-light" style={{ backgroundColor: 'rgb(124, 3, 3)' }}>
                                    <th scope="col">Data</th>
                                    <th scope="col">Assunto</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Baixar</th>
                                    <th scope="col">Remover</th>
                                </tr>
                            </thead>
                            <tbody className="table-secondary table-bordered">
                                {arrayAtaReuniao.map((ataReuni) => {
                                    return (
                                        <tr key={ataReuni.id} >
                                            <td className="p-auto" scope="row"> {ataReuni.data} </td>
                                            <td>{ataReuni.assunto}</td>
                                            <td>{ataReuni.descricao}</td>
                                            <td> <button onClick={() => baixarAtaReuniao(ataReuni.nome_arquivo)} className="btn btn-info text-light"> <i className="fas fa-download "></i> </button>  </td>
                                            <td> <button onClick={() => removerAtaReuniao(ataReuni.id, ataReuni.nome_arquivo)} className="btn btnDownload text-light"><i className="fas fa-trash"></i></button> </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='modalRemove' style={{ opacity: opacity }}>
                        <div className="row modalContent">
                            <h4 className="mr-3" > Removendo... </h4>
                            <PreloaderRing />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )


}