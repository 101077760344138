import React from 'react'
import { isAuthenticate } from './auth'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest}
            render={(props) => {
                if (isAuthenticate()) {
                    return <Component {...props} />
                } else {
                    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                }
            }
            }
        />
    )
}
