import {baseURL} from '../config/baseURL'
//REMOVER TOKEN
export const logout = () => localStorage.removeItem('token')

//INSERIR TOKEN
export const login = token => localStorage.setItem(token)

//PEGAR O TOKEN
export const getToken = () => localStorage.getItem('token')

//VERIFICAÇÃO SE EXISTE TOKEN NO LOCALSTORAGE
export const isAuthenticate = () => {

    if (localStorage.getItem('token') !== null) {
        return true
    }
    return false
}

//VERIFICAÇÃO ASSINCRONA DO TOKEN CONFERE COM O TOKEN DA API
export const authJWT = async () => {

    const token = localStorage.getItem('token')

    const urlAuth = baseURL + 'auth'

    let response = await fetch(urlAuth, {
        method: 'GET',
        mode: "cors",
        headers: {
            "authorization": "Bearer " + token
        }
    })
    let respJSON = await response.json()


    if (await !respJSON.token) {
        return false
    }

    return await true
}



