import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import './MenuSuspenso.css'

export const MenuSuspenso = () => {
    const [displayBtn, setDisplayBtn] = useState('navbar-btn mr-3')
    const tituloMenuSusp = useSelector((state) => state.titulMenuSusp)
    const active = useSelector((state) => state.data)
    const display = useSelector((state) => state.displayMenu)
    const dispatch = useDispatch()
    const history = useHistory()

    /*função para abrir e fechar o menu lateral através dos hooks*/
    function clicou() {
        active === 'active' ? dispatch({ type: 'UPDATE_ACTIVE_OF' }) : dispatch({ type: 'UPDATE_ACTIVE_ON' })
        displayBtn === 'navbar-btn mr-3' ? setDisplayBtn('navbar-btn mr-3 active') : setDisplayBtn('navbar-btn mr-3')
    }

    function logout() {

        localStorage.clear()
        history.push('/')
        history.go()
    }

    return (

        <nav style={{ display: display }} className="navbar navbar-expand-lg navbar-light bg-navbar rounded-lg">
            <div className="container-fluid">

                <button type="button" id="sidebarCollapse" className={displayBtn} onClick={() => clicou()}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>

                <div className="row">
                    <div className="col-md-12">
                        <div className="ml-0 text-center">
                            <h3 className="m-auto text-center">{tituloMenuSusp}</h3>
                        </div>
                    </div>
                </div>

                <button className="btn btnMenu ml-auto" onClick={logout}
                    type="button">
                    Sair <i className="fas fa-sign-out-alt"></i>
                </button>

            </div>
        </nav>
    )
}