import React, { useState } from 'react';
import './index.css'
import { baseURL } from '../../config/baseURL'

//Componente Preload
import { PreloaderRing } from '../../components/Preloder/Preloader'

export default function Login({ history }) {

    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [preloadRing, setPreloadRing] = useState({ display: 'none' })
    const [alertUserSenha, setAlertUserSenha] = useState('alert alert-danger col-md-12 mt-3 d-none mr-5')
    const [msgErro, setMsgErro] = useState('')

    async function handleSubmit(event) {

        setPreloadRing({ display: 'block' })
        event.preventDefault()

        const response = await fetch(`${baseURL}authenticate`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email.toUpperCase(),
                senha: senha
            })
        })

        if (response.status === 404) {
            setPreloadRing({ display: 'none' })
            setAlertUserSenha('alert alert-danger col-md-12 mt-3 d-block mr-5')
            return setMsgErro('Erro ao se conectar com o servidor, entre em contato com o administrador do sistema.')
        }

        const respJSON = await response.json()

        if (respJSON.erro) {
            setPreloadRing({ display: 'none' })
            setAlertUserSenha('alert alert-danger col-md-12 mt-3 d-block mr-5')
            setMsgErro(respJSON.erro)
        } else {

            setPreloadRing({ display: 'none' })
            localStorage.setItem('token', respJSON.token)
            localStorage.setItem('id', respJSON.id)
            localStorage.setItem('nome', respJSON.nome)
            localStorage.setItem('sexo', respJSON.sexo)
            history.push('/painel')
        }

    }

    return (
        <div className="containerEdit">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="text-center text-white mb-4"> <strong> CAXIAS Admin.</strong>  </h2>
                    <div className="row">
                        <div className="col-md-6 mx-auto">

                            <div className="card rounded-5">

                                <div className="card-header">
                                    <h3 className="mb-0 text-light">Login</h3>
                                </div>

                                <div className="card-body">

                                    <form onSubmit={handleSubmit} className="needs-validation"
                                        id="formLogin" method="POST">
                                        <div className="form-group">
                                            <label className="text-light" htmlFor="validationCustom03">Email</label>
                                            <input type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                required
                                                value={email}
                                                onChange={event => setEmail(event.target.value)}
                                                onClick={() => setAlertUserSenha('alert alert-danger col-md-12 mt-3 d-none mr-5')}
                                            />
                                            <div className="invalid-feedback">
                                                Please provide a valid city.
                                         </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="text-light" htmlFor="senhaInput" >Senha</label>
                                            <input type="password"
                                                className="form-control"
                                                id="senhaInput"
                                                required
                                                value={senha}
                                                onChange={event => { setSenha(event.target.value) }}
                                                onClick={() => setAlertUserSenha('alert alert-danger col-md-12 mt-3 d-none mr-5')} />
                                        </div>

                                        <div className="custom-control custom-checkbox mr-sm-2">
                                            <input className="custom-control-input" type="checkbox" id="customControlAutosizing" />
                                            <label className="custom-control-label text-light" htmlFor="customControlAutosizing">
                                                <h6>  Lembrar senha </h6>
                                            </label>
                                        </div>
                                        <div className="row mt-2 align-items-center justify-content-end px-3">
                                            <div style={preloadRing} > <PreloaderRing /> </div>
                                            <div className="ml-3 p-0 align-self-center">    <button type="submit" className="btn btn-danger btn-lg float-right" onClick={() => setAlertUserSenha('alert alert-danger col-md-8 my-auto d-none mr-5')} id="btnLogin">  Entrar </button> </div>
                                        </div>
                                    </form>
                                    <div className={alertUserSenha} role="alert">
                                        {msgErro}
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>

    )

}