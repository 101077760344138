import React from 'react'

export default function NotFound() {

    return (
        <>
            <h1>Erro 404</h1>
            <h3>Desculpa mas a pagina não foi encontrada com este endereço</h3>
        </>
    )
} 