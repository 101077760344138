import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './index.css'
import { baseURL } from '../../../config/baseURL'

import { PreloaderRingMin } from '../../../components/Preloder/Preloader'

export default function Solicitcoes() {
    const dispatch = useDispatch()
    dispatch({ type: 'UPDATE_SOLICITACOES' })

    useEffect(() => {
        buscarTodasSolicitacoes()
    }, [])

    const [arraySolicitacoes, setArraySolicitacoes] = useState([])
    const [conteudo_resp, setConteudo_resp] = useState('')
    const [solicitDisplay, setSolicitDisplay] = useState('d-none')
    const [naoSolcitDisplay, setNaoSolcitDisplay] = useState('d-none')
    const [displayPreload, setDisplayPreload] = useState('mx-2 my-auto d-none')
    const [alertUserSenha, setAlertUserSenha] = useState('d-none')
    const [displayAreaSolicit, setDisplayAreaSolicit] = useState("p-0")
    const [alertFilter, setAlertFilter] = useState('d-none')
    const [prioridadeFilter, setPrioridadeFilter] = useState('Todas')
    const [statusFilter, setStatusFilter] = useState('Todos')
    const [msgErro, setMsgErro] = useState('')

    async function buscarTodasSolicitacoes(status = 'Todos', prioridade = 'Todas', filter = false) {

        const response = await fetch(`${baseURL}solicitacoes-all`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                status,
                prioridade
            })
        })
        const respJSON = await response.json()

        if (respJSON.erro && !filter) {
            setNaoSolcitDisplay("col-md-12 my-5 text-center text-light p-5 rounded-pill")
            setSolicitDisplay("d-none")
            return setArraySolicitacoes([])
        } else {
            if (respJSON.erro && filter) {
                setDisplayAreaSolicit("d-none")
                setAlertFilter('alert alert-danger col mt-2 mb-1')
                return setArraySolicitacoes([])
            } else {
                setNaoSolcitDisplay("d-none")
                setDisplayAreaSolicit("p-0")
                setAlertFilter('d-none')
                setSolicitDisplay("accordion mb-3")
                return setArraySolicitacoes(respJSON)
            }
        }
    }

    async function enviarResposta(id) {

        setDisplayPreload('mx-1 my-auto')

        if (conteudo_resp.length === 0) {
            setMsgErro('A respota está em branco, digite uma resposta para ser enviada.')
            setDisplayPreload('d-none')
            return setAlertUserSenha('alert alert-danger col mt-2 mb-1')
        }

        const response = await fetch(`${baseURL}solicitacoes-resposta?id=${id}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                conteudo_resp
            })
        })

        if (response.status !== 200) {
            setMsgErro('Ocorreu algum erro interno no servidor, entre em contato com o desenvolvedor do sistema.')
            setDisplayPreload('d-none')
            return setAlertUserSenha('alert alert-danger col mt-2 mb-1')
        }

        const respJSON = await response.json()

        if (respJSON[0] !== 1) {
            setMsgErro('Ocorreu algum erro ao tentar enviar a resposta, entre em contato com o desenvolvedor do sistema.')
            setDisplayPreload('d-none')
            return setAlertUserSenha('alert alert-danger col mt-2 mb-1')
        }

        setConteudo_resp('')
        setAlertUserSenha('alert alert-success col mt-2 mb-1')
        setMsgErro('Resposta enviada com sucesso.')
        setDisplayPreload('d-none')
        buscarTodasSolicitacoes()

        /**
         * SE DER COLOCAR TBM NO MENU LATERAL A INFORMAÇÃO SE TEM OU NÃO MENSAGENS PENDENTES
         */

    }

    return (

        <div className="container">

            <div className="row  justify-content-center">
                <div className="col-md-11 py-2 px-3  rowPrincipal">
                    <div className="col-md-12 text-center">
                        <h3>Solicitações dos Sócios</h3>
                    </div>


                    <div className={naoSolcitDisplay} style={{ backgroundColor: 'rgb(124, 3, 3)' }}>
                        <h2>Até o momento, o CAXIAS não recebeu solicitações dos sócios.</h2>
                    </div>

                    <div className={solicitDisplay} id="accordionExample">

                        <div className="row mx-2">

                            <div className="col-md-6 my-3 p-3 rowPrincipal rowLaterais" >

                                <h5>Filtros de Busca:</h5>

                                <div className="form-row">
                                    <div className="form-group col text-left mr-3">
                                        <label htmlFor="sexo">Prioriadade</label>
                                        <select id="sexo" className="form-control" onClick={() => setAlertFilter('d-none')}
                                            value={prioridadeFilter} onChange={event => setPrioridadeFilter(event.target.value)} >
                                            <option value="Todas">Todas</option>
                                            <option value="Baixa">Baixa</option>
                                            <option value="Normal">Normal</option>
                                            <option value="Alta">Alta</option>
                                        </select>
                                    </div>

                                    <div className="form-group col text-left ml-3">
                                        <label htmlFor="sexo">Status</label>
                                        <select id="sexo" className="form-control" onClick={() => setAlertFilter('d-none')}
                                            value={statusFilter} onChange={event => setStatusFilter(event.target.value)} >
                                            <option value="Todos">Todos</option>
                                            <option value="Pendente">Pendente</option>
                                            <option value="Respondida">Respondida</option>
                                        </select>
                                    </div>

                                    <button type="button" onClick={() => buscarTodasSolicitacoes(statusFilter, prioridadeFilter, true)}
                                        className="btn btn-sm btn-block text-light mb-1" style={{ backgroundColor: 'rgb(124, 3, 3)' }}>Filtrar</button>

                                </div>

                            </div>

                        </div>

                        <div className={alertFilter} role="alert">
                            <strong>  Não foram encontradas solicitações com os filtros selecionados. </strong>
                        </div>

                        <div className={displayAreaSolicit} >

                            <small>Clique no <i className="fas fa-eye text-dark"></i> para visualizar,
                         ou no <i className="fas fa-reply-all"></i> para responder a solicitação.</small>
                            {
                                arraySolicitacoes.map((solicit) => {

                                    const timeSolic = new Date(solicit.createdAt);
                                    const timeResp = new Date(solicit.updatedAt);

                                    let anoSolic = timeSolic.getFullYear()
                                    let mesSolic = timeSolic.getMonth() + 1
                                    let diaSolic = timeSolic.getDate()
                                    let horaSolic = timeSolic.getHours()
                                    let minSolic = timeSolic.getMinutes()
                                    minSolic = ('00' + minSolic).slice(-2)
                                    horaSolic = ('00' + horaSolic).slice(-2)
                                    diaSolic = ('00' + diaSolic).slice(-2)
                                    mesSolic = ("00" + mesSolic).slice(-2)

                                    let anoResp = timeResp.getFullYear()
                                    let mesResp = timeResp.getMonth() + 1
                                    let diaResp = timeResp.getDate()
                                    let horaResp = timeResp.getHours()
                                    let minResp = timeResp.getMinutes()
                                    minResp = ('00' + minResp).slice(-2)
                                    horaResp = ('00' + horaResp).slice(-2)
                                    diaResp = ('00' + diaResp).slice(-2)
                                    mesResp = ("00" + mesResp).slice(-2)

                                    let dataSolicit = `${diaSolic}/${mesSolic}/${anoSolic}`
                                    let horarioSolic = `${horaSolic}:${minSolic}`
                                    let dataResp = `${diaResp}/${mesResp}/${anoResp}`
                                    let horarioResp = `${horaResp}:${minResp}`

                                    let display = ''
                                    let styleBadge = ''
                                    solicit.status === 'Respondida' ? display = 'block' : display = 'none'
                                    solicit.status === 'Respondida' ? styleBadge = 'badge badge-success py-0' : styleBadge = 'badge badge-secondary py-0'

                                    return (
                                        <div className="card" key={solicit.id}>
                                            <div className="card-header text-light py-2 pl-0 pr-4" style={{ backgroundColor: 'rgb(124, 3, 3)' }} id={`heading${solicit.id}`}>

                                                <div className="row justify-content-between" style={{ fontSize: '12px' }}>

                                                    <div className="col-md-11">
                                                        <div className="col-md-12"  >
                                                            RG: {solicit.socio.rg} - {solicit.socio.graduacao} - {solicit.socio.nome}
                                                        </div>
                                                        <div className="col-md-12">
                                                            ASSUNTO: {solicit.assunto}
                                                        </div>
                                                        <div className="col-md-12">
                                                            PRIORIDADE: {solicit.prioridade}
                                                        </div>
                                                        <div className="col-md-12">
                                                            STATUS:  <span className={styleBadge}> <h6 className="my-0">{solicit.status}</h6></span>
                                                        </div>
                                                        <div className="col-md-12">
                                                            DATA: {dataSolicit} {horarioSolic}h
                                                    </div>
                                                    </div>

                                                    <div className="col-md-1 align-self-center text-center">
                                                        <button className="btn text-light p-0 mx-1 " type="button" data-toggle="collapse"
                                                            data-target={`#collapse${solicit.id}`} aria-expanded="true" aria-controls={`collapse${solicit.id}`}>
                                                            <i style={{ height: '35px', width: '35px' }} className="fas fa-eye"></i>
                                                        </button>
                                                        <button className="btn text-info p-0 mx-1 " type="button" data-toggle="collapse"
                                                            data-target={`#collapseResp${solicit.id}`} aria-expanded="true" aria-controls={`collapseResp${solicit.id}`}>
                                                            <i style={{ height: '35px', width: '35px' }} className="fas fa-reply-all"></i>
                                                        </button>
                                                    </div>

                                                </div>

                                            </div>

                                            <div id={`collapse${solicit.id}`} className="collapse" aria-labelledby={`heading${solicit.id}`} data-parent="#accordionExample">
                                                <div className="card-body">
                                                    <p className="my-1 text-dark text-justify" > <strong style={{ color: 'black', fontWeight: '600' }} > Conteúdo da Solicitação:</strong> {solicit.conteudo_solic} </p>

                                                    <div className="col-md-12 border-top border-dark mt-3 pt-2 px-0" style={{ display: display }}>
                                                        <div className="col-md-12 text-center">  <strong style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}  >
                                                            <i className="fas fa-reply-all"></i>Resposta</strong>
                                                        </div>

                                                        <p className="my-1 text-dark" > <strong style={{ color: 'black', fontWeight: '600' }}  > Data:</strong> {dataResp} </p>
                                                        <p className="my-1 text-dark" > <strong style={{ color: 'black', fontWeight: '600' }}  > Hora:</strong> {horarioResp}h</p>
                                                        <p className="my-1 text-dark text-justify" > <strong style={{ color: 'black', fontWeight: '600' }} > Conteúdo da Resposta:</strong> {solicit.conteudo_resp} </p>
                                                    </div>

                                                </div>
                                            </div>

                                            <div id={`collapseResp${solicit.id}`} className="collapse" aria-labelledby={`heading${solicit.id}`} data-parent="#accordionExample">
                                                <div className="card-body">
                                                    <textarea className="form-control" name="" id="" cols="30" rows="8" placeholder="Caso já tenha respondido esta solicitação, a mesma será sobreposta."
                                                        value={conteudo_resp} onChange={(event) => setConteudo_resp(event.target.value)} ></textarea>

                                                    <div className="row ml-0 mt-2 p-0">
                                                        <button onClick={() => enviarResposta(solicit.id)} type="button" className="btn btn-sm my-auto text-light" style={{ backgroundColor: 'rgb(124, 3, 3)' }} >Responder</button>
                                                        <div className={displayPreload} > <PreloaderRingMin />  </div>
                                                    </div>
                                                    <div className={alertUserSenha} role="alert">
                                                        <strong>  {msgErro} </strong>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}