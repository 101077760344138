import React, { useState, useEffect } from 'react'
import './Relogio.css'

export default function Relogio() {

    const [hora, setHora] = useState(0)
    const [minutos, setMinutos] = useState(0)
    const [segundos, setSegundos] = useState(0)

    useEffect(() => {
        function updateData() {
            const date = new Date()
            setHora(('00' + date.getHours()).slice(-2))
            setMinutos(('00' + date.getMinutes()).slice(-2))
            setSegundos(('00' + date.getSeconds()).slice(-2))
        }

        setInterval(updateData, 1000)
        updateData()

    }, [])


    return (
        <strong className="strong"> {hora}:{minutos}:{segundos} </strong>
    )

}